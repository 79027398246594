import Vue from 'vue';
import VueI18n from 'vue-i18n';
import db from '@/utils/localstorage';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN';// element-ui lang
import elementArLocale from 'element-ui/lib/locale/lang/ar';
import elementPtBrLocale from 'element-ui/lib/locale/lang/pt-br';
import elementElLocale from 'element-ui/lib/locale/lang/el';
import elementEsLocale from 'element-ui/lib/locale/lang/es';
import enLocale from './en';
import zhLocale from './zh';
import arLocale from './ksa';
import brLocale from './br';
import turLocale from './tur';
import elLocale from './el';
import esLocale from './es';


Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
  ksa: {
    ...arLocale,
    ...elementArLocale,
  },
  br: {
    ...brLocale,
    ...elementPtBrLocale
  },
  tur: {
    ...turLocale
  },
  el: {
    ...elLocale,
    ...elementElLocale
  },
  es: {
    ...esLocale,
    ...elementEsLocale
  }
};

export function getLanguage() {
  // const chooseLanguage = 'en' || db.get('CLIENT_LANGUAGE', '');
  const langList = ['zh', 'en', 'ksa', 'br', 'tur', 'el', 'es']
  const locationFirstPath = location.pathname.split('/')[1]
  return langList.includes(locationFirstPath) ? locationFirstPath : 'en'
  // return db.get('CLIENT_LANGUAGE', '') || 'en'

  // if has not choose language
  const language = (navigator.language ||
      navigator.browserLanguage).toLowerCase();
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }
  return 'en';
}

const i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages,
  silentTranslationWarn: true,
});

export default i18n;
