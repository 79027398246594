<template>
  <div class="down-item">
    <div class="title">
        {{ fileName }}
        <div class="btn-close" @click="btnClose">×</div>
    </div>
    <el-progress :stroke-width="12" :percentage="process"></el-progress>
  </div>
</template>

<script>
export default {
name: 'DownloadItem',
props: {
    fileName: String,
},
data() {
    return {
        status: '',
        cancel: null,
        process: 0
    }
},
watch: {
    "$store.state.downloadList.list" : {
        deep: true,
        handler(n, o) {
            this.status = n[this.fileName].status
            this.cancel = n[this.fileName].cancel
            this.process = n[this.fileName].process
        }
    }
},
computed: {
    // status() {
    //     return this.$store.state.downloadList.list[this.fileName].status
    // },
    // cancel() {
    //     return this.$store.state.downloadList.list[this.fileName].cancel
    // },
    // process() {
    //     return this.$store.state.downloadList.list[this.fileName].process
    // }
},
methods: {
    btnClose() {
        this.cancel()
    }
}
}
</script>

<style lang="less" scoped>
.down-item {
    padding-left: 7px;
    padding-bottom: 5px;
    .title {
        padding: 5px 0;
        font-size: 14px;
        padding-right: 26px;
        position: relative;
        .btn-close {
            cursor: pointer;
            font-size: 24px;
            position: absolute;
            top: -4px;
            right: 6px;
        }
    }
}
</style>