import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap/dist/css/bootstrap.min.css';
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/style/variables.css'
import '@/style/element-variables.scss'
import i18n from '@/lang' // 全球18种语言支持
import VueClipboard from 'vue-clipboard2'

Vue.config.productionTip = false;
Vue.prototype.domainUrl = process.env.VUE_APP_VIEW_DOMAIN_PREFIX

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

Vue.use(VueClipboard);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
