import db from '@/utils/localstorage'

export default {
  namespaced: true,
  state: {
    firmProduct: db.get('FIRM_ACTIVE_PRODUCT'),
  },
  mutations: {
    setFirmProduct (state, val) {
      db.save('FIRM_ACTIVE_PRODUCT', {...state.firmProduct, ...val})
      state.firmProduct = {...state.firmProduct, ...val}
    },
  }
}
