import db from '@/utils/localstorage'

export default {
  namespaced: true,
  state: {
    comparisonList: db.get('COMPARISON_LIST', []),
  },
  mutations: {
    SET_COMPARISON_LIST (state, val) {
      db.save('COMPARISON_LIST', val)
      state.comparisonList = val
    },
    DELETE_COMPARISON_LIST (state, val) {
      state.comparisonList.splice(val, 1)
      db.save('COMPARISON_LIST', state.comparisonList)
    },
    ADD_COMPARISON_LIST (state, val) {
      if (state.comparisonList.length) {
        if (val.productClass !== state.comparisonList[0].productClass) {
          return;
        }
      }
      if (state.comparisonList.length < 4) {
        let ids = state.comparisonList.map(item => item.productId)
        if (!ids.includes(val.productId)) {
          state.comparisonList.push(val)
          db.save('COMPARISON_LIST', state.comparisonList)
        }
      }
    },
    CLEAR_COMPARISON_LIST (state) {
      db.save('COMPARISON_LIST', [])
      state.comparisonList = []
    }
  },
  actions: {
    addComparisonList({ commit }, value) {
      commit('ADD_COMPARISON_LIST', value)
    },
    deleteComparisonList({ commit }, value) {
      commit('DELETE_COMPARISON_LIST', value)
    },
    clearComparisonList({ commit }) {
      commit('CLEAR_COMPARISON_LIST')
    },
  }
}
