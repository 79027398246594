export default {
    common: {
        querySpecifications: 'الإستعلام عن المواصفات الفنية',
        login: 'تسجيل الدخول',
        logout: 'تسجيل الخروج',
        Home: 'الرئيسية',
        ProductCenter: 'مركز المنتج',
        WhatIsaSoftwareDefinedCamera: 'What Is a Software-Defined Camera',
        ProductDetail: 'تفاصيل المنتج',
        Solutions: 'الحلول',
        SolutionsDetail: 'تفاصيل الحلول',
        Products: 'المنتجات',
        Tools: 'الادوات',
        Support: 'الدعم',
        Documentation: 'الوثائق',
        news: 'مركز الاخبار',
        newsDetail: 'تفاصيل الخبر',
        forPartners: 'للشركاء',
        activity:'النشاطات',
        SoftwareDefinedCamera: 'Software-Defined Camera',
        IntelligentVisionSystem: 'نظام رؤية ذكي',
        Accessory: 'الملحقات',
        ContactUs: 'تواصل معنا',
        NewsRoom: 'الأخبار',
        NewsRoomDetail: 'تفاصيل الأخبار',
        Partner: 'الشركاء',
        Resources: 'المصادر',
        ResourceCenter: 'مركز المصادر',
        VideoLibrary: 'فيديو',
        AftersalesSupport: 'خدمات مابعد البيع',
        Aftersales: 'الأسئلة الشائعة لمابعد البيع',
        Privacy: 'الخصوصية',
        TermsOfUse: 'شروط الإستخدام',
        clear:"مسح",
        RefineBy: 'التصفية حسب',
        placeholder: 'أدخل النموذج أو الاسم أو الكلمة الأساسية',
        Favorites: 'المفضلة',
        Compare: 'مقارنة',
        More: 'المزيد',
        all: 'الكل',
        enter: 'أدخل النموذج أو الاسم أو الكلمة الأساسية',
        collapse: 'انهيار',
        expand: 'توسيع',
    },
    //导航栏
    navbar: {
        aboutTitle: 'نبذه عننا',
        about: 'نبذه عننا',
        products: 'المنتجات',
        productCenter: 'مركز المنتجات',
        technicalSupport: 'الدعم القني',
        FAQ:'الاسئلة الشائعة',
        solution: 'الحلول',
        documentation: 'الوثائق',
        support: 'الدعم',
        certificate: 'شهادة',
        pocDemo: 'PoC تجريبي',
        news: 'الاخبار',
        events:'الأحداث والفعاليات',
        tools: 'الادوات',
        partners: 'الشركاء',
        resources: 'المصادر',
        forPartners: 'للشركاء',
        forCustomers: 'للعملاء',
        channel: 'للعملاء',
        videoLibrary: 'فيديو',
        contact: 'اتصل بنا'
    },
    //合作伙伴
    partners:{
        banner:{
            title:'هولوتس معتمد',
            summery:'أخصائي الرؤية الذكية',
        },
        introduction:{
            title:'مقدمة في التدريب عبر الإنترنت',
            desc:'تقدم هذه الدورة التدريبية سلسلة كاملة من كاميرات HOLOWITS المعرفة بالبرمجيات (SDCs) ، ومسجلات الفيديو الشبكية (NVR) ، وأجهزة Micro Edge الذكية ، والحلول ، وتوفر لك العديد من المواد التعليمية والامتحانات التي تغطي المعرفة قبل البيع وما بعد البيع. يمكنك الحصول على شهادة أخصائي الرؤية الذكي المعتمد من HOLOWITS بعد اجتياز الاختبارات.',
        },
        course:{
            title:'قيمة الدورة',
            value:'ستساعدك هذه الدورة على التعرف على أبرز منتجات وحلول HOLOWITS ، وإتقان مهارات ما قبل البيع ، وتزويد العملاء بخدمات ما بعد البيع الاحترافية.',
        },
        certification:{
            title:'قيمة الشهادة',
            value:'يمكن أن تمنحك هذه الشهادة حوافًا تنافسية في السوق ، وتعرض خبرتك في منتجات HOLOWITS ، والحلول ، وخدمات ما بعد البيع ، وتتيح للعملاء وأصحاب العمل تحديد قدراتك المهنية بسهولة.',
        },
        contents:[
            {text: 'الشرائح الرئيسية التقنية HOLOWITS SDC'},
            {text: 'الشرائح الرئيسية التقنية الذكية ذات الحافة الدقيقة HOLOWITS'},
            {text: 'الشرائح الرئيسية التقنية HOLOWITS HWT-NVR800'},
            {text: 'دليل تكوين ملحقات HOLOWITS'},
            {text: 'حل HOLOWITS الذكي للبيع بالتجزئة'},
            {text: 'حل الحرم الجامعي الذكي'},
            {text: 'حل الشبكة الذكية'},
            {text: 'حل النقل الذكي'},
            {text: 'الدليل السريع لمسح موقع كاميرا الالتقاط المستهدف والتكليف بها'},
            {text: 'الدليل السريع لمسح موقع كاميرا ePolice والتكليف بها'},
            {text: 'تركيب وتشغيل HOLOWITS HWT-NVR800'},
            {text: 'HOLOWITS HWT-IVS1800 التثبيت والتشغيل'},
        ],
        learningGuide:{
            title:'دليل التعلم',
            desc:'لاجتياز الاختبارات ، يجب أن تدرس بدقة المواد ومقاطع الفيديو ذات الصلة. بعد الحصول على الشهادة ، يجب عليك تحديث الشهادة كل عامين.',
            text:'التسجيل مطلوب لأول تسجيل دخول',
            registerCode:'رمز التسجيل',
            registerValue:'UJIQ-RVH-PQW',
            btnValue:'إبدا التعلم',
        },
    },
    //关于Holowits
    aboutHWT:{
        title:'عن هولوتس',
        desc:'HOLOWITS هي مزود عالمي لمنتجات وحلول إنترنت الأشياء (IoT) الذكية القائمة على تقنيات التوعية متعددة الأبعاد ، وتدمج مجموعة واسعة من القدرات مثل البحث والتطوير والإنتاج والتوزيع والمبيعات وقدرات الخدمة. تتمتع HOLOWITS برؤية ذكية التقنيات وسلسلة صناعة كاملة وقدرات دعم فني قوية. استجابة للطلب العالمي المتزايد باستمرار على الذكاء المرئي ، تلتزم شركة HOLOWITS بتطوير الكاميرا المعرفة بالبرمجيات (SDC) المدعومة بالذكاء الاصطناعي ، وتخزين الفيديو الذكي ومنصات الحوسبة ، فضلاً عن الخدمات السحابية. يمكن تطبيق هذه العروض في مجموعة متنوعة من السيناريوهات مثل المدينة الذكية ، والنقل الذكي ، والطاقة الذكية ، والتصنيع الذكي ، والحرم الجامعي الذكي ، وتجارة التجزئة الذكية ، ونحن نهدف إلى العمل معًا لبناء عالم ذكي يستشعر كل الأشياء. لتحقيق ذلك ، نحن ملتزمون بتوفير منتجات وحلول آمنة وموثوقة وسهلة الاستخدام ومتميزة للسوق العالمية.',
    },
    // 产品中心
    products: {
        productTypes: 'نوع المنتج',
        sort: 'فرز',
        overAll: 'إجمالي',
        hot: 'رائج',
        latest: 'جديد',
        listPrice: 'إعرض الاسعار',
        preSales: 'ماقبل البيع',
        model: 'النوع',
        processor: 'معالج المعلومات',
        memory: 'الذاكرة',
        computingPower: 'القدرة الحاسوبية',
    },
    // 产品详情
    productDetail: {
        productDetail: 'تفاصيل',
        accessoryDetail: 'تفاصيل الملحقات',
        specifications: 'تحديد',
        certification: 'الشهادات',
        introduction: 'مقدمة',
        download: 'التحميل',
        huaweiCcsc: 'imgs/product_detail/mapen.png',
        clickHere: 'اضغط هنا ',
        dataSheet: 'ورقة البيانات',
        afterSalesDocuments: 'مستندات مابعد البيع',
        copy: 'Copy',
        reportError: 'ابلغ عن الأخطاء',
        description: 'الوصف',
        describeError: 'يرجى وصف الخطأ الذي وجدته.',
        picture: 'الصور',
        onlyJpg: 'يتم دعم تنسيق .jpg فقط.',
        attachment: 'المرفقات',
        selectFile: 'اختر الملفات',
        anonymous: 'Anonymous',
        submit: 'ارسال',
        accessory: 'ملحق',
        technicalSpec: 'المواصفات الفنية',
        dimensions: 'أبعاد',
        remarks: 'ملاحظات',
        optionalFitting: 'تركيب اختياري',
        favorites: 'المفضلة',
        keySpec: 'المواصفات الرئيسية',
        viewAll: 'تسجيل الدخول لعرض الكل',
        clicktoLoad: 'إضغط هنا للتحميل',
        completeSpecificationList: 'قائمة المواصفات الكاملة',
    },
    // 技术支持
    support: {
        documentation: 'توثيق',
        softwareDownload: 'تحميل برنامج',
        video: 'Video',
        searchPlaceholder: 'أدخل الكلمة المفتاحية',
    },
    // 新闻中心
    news: {
        searchPlaceholder: 'أدخل الكلمة المفتاحية',
        search: 'البحث',
    },
    // 安防工具
    securityTools: {
        cameradetectionDistance: 'حساب مسافة الكشف عن الكاميرا',
        disable: 'تعطيل',
        enable: 'تمكين',
        videoResolution: 'دقة الفيديو',
        encodingBitRate: 'Encoding Bit Rate',
        NumberOfNVRs: 'عدد NVRs',
        NumberOfSDCs: 'Number of SDCs',
        microCheckpoint: 'Micro checkpoint',
        ePoliceCheckpoint: 'ePolice/Checkpoint',
        targetDetection: 'كشف / التعرف على الهدف',
        calculateBandwidth: 'Calculate Bandwidth',
        calculateRc: 'Calculate Recording Capacity',
        NVRAccess: 'NVR Access',
        //提示信息
        microResolutionTips: 'في وضع نقطة الفحص الدقيقة ، يوصى بأن تكون دقة لوحة الترخيص 80 بكسل على الأقل.',
        microInstallationTips: 'في سيناريوهات الحارة ، يوصى بأن يتراوح ارتفاع التركيب من 4.5 م إلى 6 م. في مداخل ومخارج الحرم الجامعي ، يوصى بأن يتراوح ارتفاع التركيب من 1.3 متر إلى 1.5 متر. يمكنك ضبط ارتفاع التثبيت بناءً على متطلبات الموقع.',
        ePoliceResolutionTips: 'في وضع ePolice ، يجب أن تكون دقة لوحة الترخيص أكبر من 100 بكسل أو مساوية لها. في وضع نقطة التحقق ، إذا كان يلزم التقاط لوحات الترخيص فقط ، فيجب أن تكون دقة لوحة الترخيص أكبر من 100 بكسل أو مساوية لها. إذا كانت هناك حاجة أيضًا لالتقاط الأهداف داخل المركبات ، فيجب أن تكون دقة لوحة الترخيص أكبر من أو تساوي 180 بكسل. ',
        ePoliceInstallationTips: 'يتراوح ارتفاع التركيب الموصى به من 5.5 متر إلى 6.5 متر.',
        targetResolutionTips: 'الكشف عن الهدف: بالنسبة للكاميرات ذات التركيز القصير (أقل من 12 مم) ، تبلغ دقة الهدف الموصى بها 80 بكسل على الأقل. بالنسبة للكاميرات ذات التركيز البؤري المتوسط ​​والطويل (12 مم أو أكثر) ، تبلغ الدقة المستهدفة الموصى بها 120 بكسل على الأقل. التعرف على الهدف: بالنسبة للكاميرات ذات التركيز القصير (أقل من 12 مم) ، فإن التعرف على الهدف الموصى به هو 110 بكسل على الأقل. بالنسبة للكاميرات ذات التركيز البؤري المتوسط ​​والطويل (12 مم أو أكثر) ، تبلغ الدقة المستهدفة الموصى بها 120 بكسل على الأقل.',
        targetInstallationTips: 'الكشف عن الهدف: بالنسبة للكاميرات ذات البعد البؤري الثابت 2.8 مم أو 3.6 مم ، يتراوح ارتفاع التثبيت الموصى به من 2 م إلى 2.5 م. بالنسبة للكاميرات ذات البعد البؤري الثابت 6 مم ، يتراوح ارتفاع التثبيت الموصى به من 2 م إلى 3 م. بالنسبة للكاميرات ذات الأبعاد البؤرية الأخرى ، يتراوح ارتفاع التثبيت الموصى به من 2.5 مترًا إلى 3.5 مترًا. التعرف على الهدف: بالنسبة للكاميرات قصيرة التركيز ، يتراوح ارتفاع التثبيت الموصى به من 2.5 مترًا إلى 3 مترًا. بالنسبة للكاميرات طويلة التركيز ، يتراوح ارتفاع التثبيت الموصى به من 2.5 مترًا إلى 3.5 مترًا.',
    },

    // 提示信息
    tipInfo: {
        exportFailure: 'فشل التصدير.',
        addProToComp: 'أضف المنتج للمقارنة.',
        selectLeastTwo: 'حدد منتجين على الأقل للمقارنة.',
        selectMaximum: 'يمكنك تحديد أربعة منتجات كحد أقصى للمقارنة.',
        copySuccess: 'نسخ النجاح.',
        noData: 'لايوجد بيانات.',
        selectOther: 'الجهاز المحدد لا يوجد بيانات. الرجاء تحديد جهاز آخر.',
        onlyJpg: 'يتم دعم صور JPG فقط.',
        exeNoUpload: 'لا يمكن تحميل ملفات EXE.',
        problemNotEmpty: 'لا يمكن ترك وصف المشكلة فارغًا.',
        submitSuccess: 'تم الإرسال بنجاح.',
        submitFailed: 'فشل التقديم. حاول مرة أخرى في وقت لاحق.',
        loginSuccess: 'النجاح في تسجيل الدخول',
        errorPwd:'كلمتا المرور غير متسقتين',
    },
    technologySupport: {
        support: 'مساعدة',
    },
    documentation: {
        searchPlaceholder: 'أدخل الكلمة الأساسية',
        search: 'بحث',
        productFilter: 'المنتج',
        typeFilter: 'النوع',
        formatFilter: 'شكل',
        publishDate: 'فرز حسب تاريخ الإصدار',
        downloadTimes: 'فرز حسب عدد التنزيلات',
        hotDoc: 'وثيقة رائجة',
        result: 'النتيجة',
        download: 'التحميل',
        batDownload: 'تحميل دفعه',
        checkAll: 'اختيار الكل',
        paginalNumber: 'رقم الصفحة',
    },
    faq: {
        faq: 'التعليمات',
    },
    contactUs: {
        contactUsPricingInfo:'اتصل بنا لمعلومات التسعير',
        contactUs: 'تواصل معنا',
        options: {
            pricingInfo: 'التسعير / المعلومات',
            serviceRequests: 'طلب خدمة',
        },
        pricingInfo: {
            Title: 'تواصل معنا',
            Desc1: 'يرجى ملء النموذج أدناه.',
            Desc2: 'سوف نتصل بك قريبا.',
            Mandatory: 'إلزامي',
            FirstName: 'الإسم الاول',
            LastName: 'اسم العائلة',
            Email: 'البريد الإلكتروني',
            Telephone: 'رقم الهاتف',
            CountryRegion: 'العدد / المدينة',
            Company: 'الشركة',
            Industry: 'المجال',
            JobTitle: 'مسمى وظيفي',
            FavoriteProducts: 'المنتجات المفضلة',
            Budget: 'الميزانية',
            BrieflyPlaceholder: 'حدد بإيجاز متطلبات مشروعك. ',
            ConfirmText: 'أوافق على تلقي معلومات حول المنتجات والحلول والخدمات والعروض من شركاء HOLOWITS و HOLOWITS المعتمدين. أدرك أنه يمكنني إلغاء الاشتراك في أي وقت.',
            Yes: 'نعم',
            No: 'لا',
            AgreeText: 'أفهم وأوافق على استخدام ونقل معلوماتي الشخصية بواسطة HOLOWITS وفقًا لـ HOLOWITS ',
            PrivacyPolicy: 'سياسة الخصوصية',
            TermsOfUse: 'شروط الاستخدام',
            And: 'و',
            placeholder: 'الرجاء الإدخال',
            submit: 'يُقدِّم',
            fieldRequired: 'هذه الخانة مطلوبه.',
            emailValidator: {
                'at-error': 'An email address must contain a &quot;@&quot; symbol.',
                'account-letter-forbidden': 'يحتوي اسم البريد الإلكتروني (مثال: Adam.Smith@abc.com) على أحرف غير صالحة.',
                'prevDot-error': 'يجب ألا يبدأ اسم البريد الإلكتروني (على سبيل المثال: Adam.Smith@abc.com) أو ينتهي بنقطة أو نقطة (".").',
                'double-dot-error:': 'نقطتان أو نقطتان متتاليتان (".") معًا غير صالح.',
                'domain-error': 'يجب أن يحتوي المجال (مثال: Adam.Smith@abc.com) على اسم مجال واحد على الأقل.',
                'length-error': 'يجب ألا يتجاوز عنوان البريد الإلكتروني 254 حرفًا.',
                'nextLetter-forbidden': 'المجال (مثال: Adam.Smith@abc.com) يحتوي على أحرف غير صالحة.',
                'nextLine-error': 'يجب ألا يبدأ المجال (على سبيل المثال: Adam.Smith@abc.com) أو ينتهي بشرطة ("-").',
                'account-length-error': 'يجب ألا يتجاوز اسم البريد الإلكتروني (مثال: Adam.Smith@abc.com) 63 حرفًا.',
            },
        },
    },
};
