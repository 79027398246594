export default {
    common: {
      querySpecifications: 'Teknik özellikleri sorgula',
      login: 'Giriş Yap',
      logout: 'Çıkış Yap',
      Home: 'Ev',
      ProductCenter: 'Ürün Merkezi',
      WhatIsaSoftwareDefinedCamera: 'Yazılım Tanımlı Kamera Nedir',
      ProductDetail: 'Ürün Detayı',
      Solutions: 'Çözümler',
      SolutionsDetail: 'Çözüm Detayı',
      Products: 'Ürünler',
      Tools: 'Araçlar',
      Support: 'Destek',
      Documentation: 'Dökümantasyon',
      news: 'Haberler',
      newsDetail: 'Haber Detayı',
      forPartners: 'Partnerler için',
      activity:'Aktiviteler',
      SoftwareDefinedCamera: 'Yazılım-Tabanlı Kamera',
      IntelligentVisionSystem: 'IVS',
      Accessory: 'Aksesuarlar',
      ContactUs: 'Bize Ulaşın',
      NewsRoom: 'Haber Odası',
      NewsRoomDetail: 'Haber Odası Detayı',
      Partner: 'Partner',
      Resources: 'Kaynaklar',
      ResourceCenter: 'Kaynak Merkezi',
      VideoLibrary: 'Video Kütüphanesi',
      AftersalesSupport: 'Satış Sonrası Destek',
      Aftersales: 'Satın Sonrası Soru-Cevap',
      Privacy: 'Gizlilik',
      TermsOfUse: 'Kullanım Şartları',
      clear:"Temizle",
      RefineBy: 'Refine By',
      placeholder: 'Model adı veya anahtar kelimeyi girin',
      Favorites: 'Favoriler',
      Compare: 'Karşılaştır',
      More: 'Daha fazla',
      all: 'Hepsi',
      enter: 'Model adı veya anahtar kelimeyi girin',
      collapse: 'Collapse',
      expand: 'Genişlet',
      confirm:"Onayla",
      cancel:"İptal Et",
      channel:"Kanal",
    },
    login: {
      title: 'Kullanıcı Kimliği ile Giriş Yapın',
      email: 'Email',
      pwd: 'Şifre',
      changePwd: 'Şifreyi Değiştir',
      forgetPwd: 'Şifremi Unuttum',
      register: 'Kayıt ol',
      rulesEmail: 'Email adresi geçersiz.',
    },
    //导航栏
    navbar: {
      aboutTitle: 'Hakkında',
      about: 'Holowits Hakkında',
      products: 'Ürünler',
      productCenter: 'Ürüm Merkezi',
      technicalSupport: 'Destek',
      FAQ:'SSS',
      solution: 'Çözümler',
      documentation: 'Dokümantasyon',
      support: 'Destek',
      certificate: 'Serfitika',
      pocDemo: 'PoC Demo',
      news: 'Haber Odası',
      events:'Eventler',
      tools: 'Araçlar',
      partners: 'Partner',
      resources: 'Kaynaklar',
      forPartners: 'Partnerler için',
      forCustomers: 'Müşteriler için',
      channel:"Müşteriler için",
      videoLibrary: "Video",
      contact: "Bize Ulaşın",
      algorithmstore: "Algoritma Merkezi",
      terms: "Politikalar, Şartlar, Koşullar",
      warrantypolicy: "Garanti Poliçesi", 
    },
    home: {
      'whatIsNew': "Yeni ne var?",
      'ProductSpotlight': 'Ürün tanıtımı',
      'Events&News': 'Etkinlikler ve Haberler',
      'learnMore': 'Daha fazlası'
    },
    //合作伙伴
    partners:{
      banner:{
        title:'Holowits Serfitika',
        summery:'Akıllı Görüş Uzmanı',
      },
      introduction:{
        title:'Çevrimiçi Derslere Giriş',
        desc:`Bu kurs, HOLOWITS yazılım tanımlı kameraların (SDC'ler), ağ video kayıt cihazlarının (NVR'ler), akıllı Micro Edge cihazlarının ve çözümlerinin tam bir serisini tanıtır ve size satış öncesi ve satış sonrası bilgileri kapsayan çeşitli öğrenme materyalleri ve sınavlar sağlar. Sınavları geçtikten sonra HOLOWITS Sertifikalı Akıllı Görüş Uzmanı sertifikasını alabilirsiniz.`,
      },
      course:{
        title:'Kurs Değeri',
        value:'Bu kurs, HOLOWITS ürün ve çözümlerinin öne çıkan özelliklerini öğrenmenize, satış öncesi becerilerde uzmanlaşmanıza ve müşterilere profesyonel satış sonrası hizmetler sunmanıza yardımcı olacaktır.',
      },
      certification:{
        title:'Sefitika Değeri',
        value:'Bu sertifika size pazarda rekabet avantajı sağlayabilir, HOLOWITS ürünleri, çözümleri ve satış sonrası hizmetlerdeki uzmanlığınızı sergileyebilir ve müşterilerin ve işverenlerin profesyonel yeteneklerinizi kolayca belirlemesine olanak sağlayabilir.',
      },
      contents:[
        {text: 'HOLOWITS SDC Teknik Ana Slaytlar'},
        {text: 'HOLOWITS Intelligent Micro Edge Teknik Ana Slaytlar'},
        {text: 'HOLOWITS HWT-NVR800 Teknik Ana Slaytlar'},
        {text: 'HOLOWITS Aksesuar Yapılandırma Kılavuzu'},
        {text: 'HOLOWITS Akıllı Perakende Çözümü'},
        {text: 'HOLOWITS Akıllı Kampüs Çözümü'},
        {text: 'HOLOWITS Akıllı Şebeke Çözümü'},
        {text: 'HOLOWITS Akıllı Ulaşım Çözümü'},
        {text: 'Hedef Yakalama Kamera Saha İncelemesi ve Devreye Alma için Hızlı Kılavuz'},
        {text: 'ePolice Kamera Saha İncelemesi ve Devreye Alma için Hızlı Kılavuz'},
        {text: 'HOLOWITS HWT-NVR800 Kurulum ve Devreye Alma'},
        {text: 'HOLOWITS HWT-IVS1800 Kurulum ve Devreye Alma'},
        {text: 'HOLOWITS A serisi Kamera ve XVR Tanıtımı'},
        {text: 'HOLOWITS UYGULAMASI Kullanım Kılavuzu'},
        {text: 'HOLOWITS E Serisi Kamera Tanıtımı'},
      ],
      learningGuide:{
        title:'Öğrenme Rehberi',
        desc:'Sınavları geçmek için ilgili materyalleri ve videoları titizlikle incelemelisiniz. Sertifikayı aldıktan sonra, sertifikayı iki yılda bir güncellemeniz gerekir.',
        text:'İlk giriş için kayıt gereklidir',
        registerCode:'Kayıt Kodu',
        registerValue:'UJIQ-RVH-PQW',
        btnValue:'Öğrenmeye başla',
      },
    },
    //关于Holowits
    aboutHWT:{
      title:'HOLOWITS Hakkında',
      desc:'HOLOWITS, çok boyutlu farkındalık teknolojilerine dayalı akıllı Nesnelerin İnterneti (IoT) ürünleri ve çözümlerinin küresel bir sağlayıcısıdır ve Ar-Ge, üretim, dağıtım, satış ve hizmet yetenekleri gibi çok çeşitli yetenekleri entegre eder. HOLOWITS, akıllı vizyona sahiptir. teknolojiler, tam teşekküllü bir endüstri zinciri ve güçlü teknik destek yetenekleri. Görsel zekaya yönelik sürekli artan küresel talebe yanıt veren HOLOWITS, yapay zeka özellikli Yazılım Tabanlı Kamera (SDC), akıllı video depolama ve bilgi işlem platformlarının yanı sıra bulut hizmetleri geliştirmeye kendini adamıştır. Bu teklifler, akıllı şehir, akıllı ulaşım, akıllı enerji, akıllı üretim, akıllı kampüs ve akıllı perakende gibi çok çeşitli senaryolarda uygulanabilir. Her şeyin algılandığı akıllı bir dünya oluşturmak için birlikte çalışmayı amaçlıyoruz. Bunu başarmak için kendimizi küresel pazar için güvenli, güvenilir, kullanımı kolay ve olağanüstü ürünler ve çözümler sunmaya adadık.',
    },
    // 产品中心
    products: {
      productTypes: 'Ürün Tipi',
      sort: 'Düzenle',
      overAll: 'Tümü',
      hot: 'Hot',
      latest: 'Yeni',
      listPrice: 'Fiyat Listesi',
      preSales: 'Satış Öncesi',
      model: 'Model',
      processor: 'İşlemci',
      memory: 'Hafıza',
      computingPower: 'İşlem Gücü',
      series: 'Serisi',
      form: 'Form'
    },
    // 产品详情
    productDetail: {
      productDetail: 'Detaylar',
      accessoryDetail: 'Aksesuar Detayları',
      specifications: 'Özellikler',
      certification: 'Sertifikalar',
      introduction: 'Tanıtım',
      download: 'İndir',
      documentation: 'Dokümantasyon',
      huaweiCcsc: 'imgs/product_detail/mapen.png',
      clickHere: 'Buraya tıklayın',
      dataSheet: 'Datasheet',
      afterSalesDocuments: 'Satış sonrası dokümanlar',
      copy: 'Kopyala',
      reportError: 'Rapor Hatası',
      description: 'Açıklama',
      describeError: 'Lütfen bulduğunuz hatayı açıklayın.',
      picture: 'Resim',
      onlyJpg: 'Yalnızca .jpg biçimi desteklenir.',
      attachment: 'Ek dosya',
      selectFile: 'Dosya Seçin',
      anonymous: 'Anonim',
      submit: 'Gönder',
      accessory: 'Aksesuar',
      technicalSpec: 'Teknik Özellij',
      dimensions: 'Boyutlar',
      remarks: 'Notlar',
      optionalFitting: 'Opsiyonel Montaj',
      favorites: 'Favoriler',
      keySpec: 'Anahtar Özellikler',
      viewAll: 'Tümünü Görüntülemek için Giriş Yapın',
      clicktoLoad: 'İndirmek için tıklayın',
      completeSpecificationList: 'Eksiksiz Şartame Listesi',
    },
    // 技术支持
    support: {
      wahtDoYouNeedHelpWith: 'Hangi konuda yardıma ihtiyacınız var?',
      title: 'HOLOWITS Teknik Desteğine Hoş Geldiniz',
      explain: 'HOLOWITS Teknik Destek, HOLOWITS ürünleriniz ve çözümleriniz için birinci sınıf destek sağlar. Dünyanın dört bir yanındaki çevrimiçi destek hizmetlerimiz ve mühendislerimiz size her zaman, her yerde yardımcı olmaya hazır.',
      documentation: 'Dökümantasyon',
      softwareDownload: 'Yazılım indir',
      video: 'Video',
      searchPlaceholder: 'Anahtar Kelime Girin',
    },
    contact: {
      theme: 'Teknik Desteğe mi ihtiyacınız var?',
      content: 'Teknik destek için ülkenizdeki veya bölgenizdeki sorumlu HOLOWITS ulusal distribütörü ile iletişime geçmeniz önerilir.',
      tip1: 'HOLOWITS distribütörleri ve bayileri ile iletişime geçmek için',
      tip2: 'HOLOWITS ile iletişime geçmek için şu adrese e-posta gönderin:',
      button1: 'Buraya tıklayın',
      button2: 'support@holowits.com',
      title: '',
      explain: ''
    },
      // 渠道中心
    channel: {
      partnerName: 'Partner İsmi',
      countryOrRegion: 'Ülke/Bölge',
      partnerType: 'Partner Tipi',
      searchPlaceholder:"Partner Listesinde Arayın",
      website: 'Web Site',
      email: 'Email',
      phone: 'Telefon',
      address: 'Adres',
    },
    // 新闻中心
    news: {
      searchPlaceholder: 'Anahtar kelime girin',
      search: 'Ara',
    },
    // 安防工具
    securityTools: {
      cameradetectionDistance: 'Kameranın Algılama Mesafesini Hesaplayın',
      disable: 'Devre dışı bırak',
      enable: 'Aktif Et',
      videoResolution: 'Video çözünürlüğü',
      encodingBitRate: 'Bit Hızı',
      NumberOfNVRs: 'NVR sayısı',
      NumberOfSDCs: 'SDC sayısı',
      microCheckpoint: 'Mikro kontrol noktası',
      ePoliceCheckpoint: 'ePolice/Kontrol Noktası',
      targetDetection: 'Hedef Algılama/tanıma',
      calculateBandwidth: 'Bant Genişliğini Hesapla',
      calculateRc: 'Kayıt Kapasitesini Hesapla',
      NVRAccess: 'NVR Erişimi',
      ipTools: 'IP Video Sistemi Tasarım Aracı',
      //提示信息
      microResolutionTips: 'Mikro kontrol noktası modunda, plaka çözünürlüğünün en az 80 piksel olması önerilir.',
      microInstallationTips: 'Şeritli senaryolarda, kurulum yüksekliği aralığının 4,5 m ila 6 m arasında olması önerilir. Kampüs giriş ve çıkışlarında montaj yüksekliği aralığının 1,3 m ile 1,5 m arasında olması tavsiye edilir. Kurulum yüksekliğini site gereksinimlerine göre ayarlayabilirsiniz.',
      ePoliceResolutionTips: 'ePolice modunda, plaka çözünürlüğü 100 pikselden büyük veya buna eşit olmalıdır. Kontrol noktası modunda, yalnızca plakaların yakalanması gerekiyorsa, plaka çözünürlüğü 100 pikselden büyük veya buna eşit olmalıdır. Araçların içindeki hedeflerin de yakalanması gerekiyorsa, plaka çözünürlüğü 180 pikselden büyük veya buna eşit olmalıdır. ',
      ePoliceInstallationTips: 'Önerilen kurulum yüksekliği 5,5 m ila 6,5 m arasındadır.',
      targetResolutionTips: `Hedef algılama: Kısa odaklı (12 mm'den az) kameralar için önerilen hedef çözünürlüğü en az 80 pikseldir. Orta ve uzun odaklı (12 mm veya daha fazla) kameralar için önerilen hedef çözünürlük en az 120 pikseldir. Hedef tanıma: Kısa odaklı (12 mm'den az) kameralar için önerilen hedef tanıma en az 110 pikseldir. Orta ve uzun odaklı (12 mm veya daha fazla) kameralar için önerilen hedef çözünürlük en az 120 pikseldir.`,
      targetInstallationTips: 'Hedef algılama: 2,8 mm veya 3,6 mm sabit odak uzaklığına sahip kameralar için önerilen kurulum yüksekliği 2 m ila 2,5 m arasındadır. 6 mm sabit odak uzaklığına sahip kameralar için önerilen kurulum yüksekliği 2 m ile 3 m arasındadır. Farklı odak uzunluklarına sahip kameralar için önerilen kurulum yüksekliği 2,5 m ile 3,5 m arasındadır. Hedef tanıma: Kısa odaklı kameralar için önerilen kurulum yüksekliği 2,5 m ila 3 m arasındadır. Uzun odaklı kameralar için önerilen kurulum yüksekliği 2,5 m ile 3,5 m arasındadır.',
    },
  
    // 提示信息
    tipInfo: {
      exportFailure: 'Dışa aktarma hatası',
      addProToComp: 'Karşılaştırmak için ürünü ekleyin.',
      selectLeastTwo: 'Karşılaştırma için en az iki ürün seçin.',
      selectMaximum: 'You can select a maximum of four products for comparison.',
      copySuccess: 'Kopyalama başarılı.',
      noData: 'Data yok.',
      selectOther: 'Seçilen cihaz bilgi yok. Lütfen başka bir cihaz seçin.',
      onlyJpg: 'Yalnızca JPG destekler.',
      exeNoUpload: 'EXE dosyaları yüklenemez.',
      problemNotEmpty: 'Sorun açıklaması boş olamaz.',
      submitSuccess: 'Başarıyla gönderildi.',
      submitFailed: 'Gönderme işlemi başarız oldu. Daha sonra tekrar deneyin.',
      loginSuccess: 'Giriş başarılı',
      errorPwd:'Şifreler eşleşmiyor',
      confirmLogout:'Hesaptan çıkmak istediğinize emin misiniz?',
      tooManyRequest:'Çok sık kimlik doğrulama kodun alındı. Lütfen 3 dakika içinde tekrar deneyin',
      getCodeImageFailed:'Resim doğrulama kodu hatalı',
    },
    technologySupport: {
      support: 'Destek',
    },
    // 文档中心
    documentation: {
      searchPlaceholder: 'Anahtar kelimeyi girin',
      search: 'Ara',
      productFilter: 'Ürün',
      typeFilter: 'Tip',
      languageFilter: 'Dil',
      formatFilter: 'Format',
      publishDate: 'Yayınlanma tarihine göre sırala',
      downloadTimes: 'İndirme sayısına göre sırala',
      hotDoc: 'Sık Kullanılan Belgeler',
      result: 'Sonuç',
      download: 'İndir',
      batDownload: 'Toplu İndir',
      checkAll: 'Tümünü Kontrol Et',
      paginalNumber: 'Sayfa Numarası',
    },
    // 视频中心
    videoLibrary: {
      title: 'Video Kütüphanesi',
      searchVideo: 'Video Ara',
      all: 'Hepsi',
      branding: 'Marka',
      products: 'Ürünler',
      solutions: 'Çözümler',
      technicalSupport: 'Teknik Destek',
      training: 'Training',
      hOLOWITSClub: 'HOLOWITS Club'
    },
    faq: {
      faq: 'SSS',
      Products: 'Ürünler',
      Solutions: 'Çözümler',
      Partners: 'Partnerler',
      Marketing: 'Pazarlama',
      Others: 'Diğerleri',
      placeholder: `boş bırakın ve tüm SSS'leri alırsınız`,
      help: 'SSS araması nasıl kullanılır?'
    },
    learnRegister: {
      pageTitle: "HOLOWITS Sertifikalı Uzmanlık Sınavı Kaydı",
      pageDesc1: "Lütfen aşağıdaki formu doldurunuz.",
      pageDesc2: "Doğrulamadan sonra size e-posta göndereceğiz.",
      hint1: "Lütfen şirketinizin bir çalışanı olduğunuzu kanıtlayın (Personel kartınızı veya kartvizitinizi yükleyebilirsiniz)",
      hint2: "Lütfen şirket işletme lisansınızı yükleyin",
      upload: "Yükle",
      successPanelTitle: "Başarıyla gönderildi",
      successPanelText: "Başvuru gönderildi ve yönetici sizinle e-posta yoluyla iletişime geçecek.\nŞirketiniz Sertifikalı Kademe 2 iş ortağımız olmak istiyorsa, kaydolmak için lütfen buraya tıklayın. Şirketinizin bir çalışanı HOLOWITS Sertifikalı Uzmanlık Sınavını geçtikten sonra Sertifikalı Tier 2 iş ortağımız olabilir ve web sitemizi de gösterebilir.",
      inputPlaceholder: "Lütfen HOLOWITS uzmanlık sınavımıza neden katıldığınızı kısaca açıklayın",
    },
    register: {
      register: "Kayıt Ol",
      email: "Email",
      companyName: "Firma Adı",
      name: "Adınız",
      country: "Ülke/Bölge",
      description: "Please describe the relationship between you or your company with HOLOWITS",
      code: "Verification Code",
      cancel: "Cancel",
      submit: "Submit",
      successPanelTitle: "Submitted successfully",
      successPanelText: "Lütfen HOLOWITS ile sizin veya şirketiniz arasındaki ilişkiyi açıklayınız.",
      back: "Geri",
      rules: {
        email: "E-posta boş olamaz.",
        companyName: "Firma ismi boş olamaz.",
        name: "İsim boş olamaz.",
        country: "Ülke/Bölge boş olamaz.",
        description: "açıklama boş olamaz.",
        code: "kod boş olamaz."
      }
    },
    reseller: {
      pageTitle: "2. KADEME PARTNER OLUN",
      companyName: "FİRMA İSMİ",
      webSite: "Resmi Web Sitesi URL",
      region: "Bölge",
      coopCountry: "Ülke/Bölge",
      preSales: "Satış öncesi destek için e-posta",
      postSales: "Satış sonrası destek için e-posta",
      marketing: "Pazarlama desteği için e-posta",
      email: "Sizinle iletişim kurmak için e-posta",
      phone: "Satış öncesi destek için Telefon Numarası",
      address: "Şirket Adresi",
      partnerType: "Partner Tipi",
      uploadLog: "Şirket logonuzun (jpg. veya png.) yüksek çözünürlüklü olarak yükleyin.",
      upload: "Yükle",
      downlaod: "Sözleşme şablonunu indirin",
      businessLicense: "Şirket işletme lisansınızı yükleyin (50M'den büyük dosya) (pdf.png.jpg.jpeg.webp.formatını destekler)",
      agreeFieldText: "HOLOWITS'e şirketimi HOLOWITS web sitelerinde listeleme yetkisi veriyorum.",
      successPanelTitle: "Başarıyla gönderildi",
      successPanelText: "Başvuru gönderildi ve sizinle e-posta yoluyla iletişime geçeceğiz.",
    },
    contactUs: {
      contactUsPricingInfo:'Fiyatlandırma Bilgisi için Bize Ulaşın',
      contactUs: 'Bize Ulaşın',
      options: {
        pricingInfo: 'Fiyatlandırma/Bilgi',
        serviceRequests: 'Hizmet Talepleri',
      },
      pricingInfo: {
        Title: 'Bize Ulaşın',
        Desc1: 'Lütfen aşağıdaki formu doldurunuz.',
        Desc2: 'Yakında sizinle iletişime geçeceğiz.',
        Mandatory: 'Zorunlu',
        FirstName: 'İsim',
        LastName: 'Soyisim',
        Email: 'Email',
        Telephone: 'Telefon',
        CountryRegion: 'Ülke/Bölge',
        Partner: 'Partner',
        Company: 'Firma',
        Industry: 'Endüstri',
        JobTitle: 'İş unvanı',
        FavoriteProducts: 'Favori Ürünler',
        Budget: 'Bütçe',
        BrieflyPlaceholder: 'Proje gereksinimlerinizi kısaca özetleyin.',
        ConfirmText: 'HOLOWITS ve HOLOWITS yetkili ortaklarından ürünler, çözümler, hizmetler ve teklifler hakkında bilgi almayı kabul ediyorum. İstediğim zaman abonelikten çıkabileceğimi anlıyorum.',
        Yes: 'Evet',
        No: 'Hayır',
        AgreeText: `Kişisel bilgilerimin HOLOWITS tarafından HOLOWITS'e göre kullanılmasını ve aktarılmasını anlıyorum ve kabul ediyorum.`,
        PrivacyPolicy: 'Gizlilik Politikası',
        publictiy: 'Şirketinizin bir çalışanı HOLOWITS Sertifikalı Uzman Sınavını geçtikten sonra Sertifikalı Tier 2 İş Ortağımız olabilir ve web sitemizde de gösterilebilir.',
        TermsOfUse: 'Kullanım Şartları',
        And: 'Ve',
        placeholder: 'Lütfen giriniz.',
        submit: 'Gönder',
        fieldRequired: 'Bu alan gereklidir.',
        emailValidator: {
          'at-error': 'Bir e-posta adresi &quot;@&quot; sembol.',
          'account-letter-forbidden': 'E-posta adı (örnek: Adam.Smith@abc.com) geçersiz karakterler içeriyor.',
          'prevDot-error': 'E-posta adı (örnek: Adam.Smith@abc.com) nokta veya nokta (“.”) ile başlamamalı veya bitmemelidir.',
          'double-dot-error:': 'Ardışık iki nokta veya nokta (“.”) birlikte geçersizdir.',
          'domain-error': 'Etki alanı (örnek: Adam.Smith@abc.com) en az bir alan adı içermelidir.',
          'length-error': 'Bir e-posta adresi 254 karakteri geçmemelidir.',
          'nextLetter-forbidden': 'Etki alanı (örnek: Adam.Smith@abc.com) geçersiz karakterler içeriyor.',
          'nextLine-error': 'Etki alanı (örnek: Adam.Smith@abc.com) kısa çizgi (“-“) ile başlamamalı veya bitmemelidir.',
          'account-length-error': 'Bir e-posta adı (örnek: Adam.Smith@abc.com) 63 karakteri geçmemelidir.',
        },
        phoneFormatError: 'Örnek : Alan Kodu(3-6 DIGITAL, örn.0066)Telefon Numarası'
      },
    },
    algorithm: {
      pageTitle: "HOLOWITS Algoritma Merkezi",
      navOptionTag: "Akıllı Algoritma",
      recommendation: "İlgili ürünler önerisi",
      selectorItemTitle: {
       "Product Application Scenario": "Ürün Uygulama Senaryosu",
       "Algorithm Classification": "Algoritma Sınıflandırması",
       "Applicable Platform": "Uygulanabilir Platform",
       "Algorithm type": "Algoritma Türü"
      },
      tag: {
       "All": "Tümü",
       "Transportation": "Ulaşım",
       "Government": "Devlet",
       "Finance": "Finans",
       "Energy": "Enerji",
       "Public Security": "Kamu güvenliği",
       "Personnel Detection": "Personel Tespiti",
       "Intelligent Transportation": "Akıllı Ulaşım",
       "Text Detection": "Metin Algılama",
       "Behavior Analysis": "Davranış Analizi",
       "Voice Detection": "Ses Algılama",
       "Video Analysis": "Video Analizi",
       "Perimeter Events": "Perimeter Events",
       "Event Detection": "Olay Tespiti",
       "Object Detection": "Nesne Algılama",
       "Detection Analysis": "Tespit Analizi",
       "SDC": "SDC",
       "HWT-IVS1800": "HWT-IVS1800",
       "HOLOWITS": "HOLOWITS",
       "HOLOWITS SELECTED": "HOLOWITS Önerisi",
       "OTHER": "Diğerleri",
      },
      label: {
       "Subway": "Metro",
       "Highway": "Otoyol",
       "Railway": "Demiryolu",
       "Airport": "Havalimanı",
       "Public Transport": "Toplu taşıma",
       "Digital Government": "Dijital Devlet",
       "Bank": "Banka",
       "Insurance": "Sigorta",
       "Wind Power": "Rüzgar Enerjisi",
       "Solar Energy": "Güneş Enerjisi",
       "Electric Power": "Elektrik Enerjisi",
       "Gas": "Gaz",
       "Water Conservancy": "Su Koruma",
       "Petroleum": "Petrol",
       "Forest": "Orman",
       "City Management": "Şehir Yönetimi",
       "Fire Detection": "Yangın Algılama",
       "Manufacturing": "Üretim",
       "Agriculture": "Tarım",
       "Retail": "Perakende",
       "Logistics": "Lojistik",
       "Service": "Hizmet",
       "Medical Treatment": "Tıbbi tedavi",
       "Catering Industry": "Catering Industry",
       "Tourism": "Turizm",
       "Education": "Eğitim",
       "Campus": "Kampüs",
       "Family": "Aile",
       "Internet of Things(IoT)": "Nesnelerin İnterneti (IoT)",
       "Target Recognition": "Hedef Tanıma",
       "Personnel Attribute Analysis": "Personel Öznitelik Analizi",
       "Overall Target Detection": "Genel Hedef Tespiti",
       "License Plate detection": "Plaka tespiti",
       "Vehicle Attribute Detection": "Araç Öznitelik Tespiti",
       "Non-standard Vehicle Inspection": "Standart Dışı Araç Muayenesi",
       "Illegal Detection": "Yasadışı Tespit",
       "Vehicle Detection": "Araç Algılama",
       "Omni-data Structuring": "Omni-data Yapılandırması",
       "OCR": "OCR",
       "Behavior Analysis": "Davranış Analizi",
       "Voice Synthesis": "Ses Sentezi",
       "Voice Identification": "Ses Tanıma",
       "Video Abstract": "Video Özeti",
       "Perimeter Intrusion": "Çevre İhlali",
       "Water Pollution Detection": "Su Kirliliği Tespiti",
       "Carrying Dangerous Objects": "Tehlikeli Nesneleri Taşıma",
       "Carrying Prohibited Items": "Yasaklı Eşyaları Taşıma",
       "Construction Machinery Detection": "İş Makinaları Tespiti",
       "Foreign Matter Detection": "Yabancı Madde Tespiti",
       "Wearing Detection": "Kıyafet Tespiti",
       "Equipment Status Detection": "Ekipman Durum Tespiti",
       "Instrumentation Detection": "Alet Tespiti",
       "Defect Detection": "Hata Tespiti",
       "Remote Sensing": "Uzaktan Algılama",
       "Robot": "Robot",
       "X Series": "X Serisi",
       "M Series": "M Serisi",
       "C Series": "C Serisi",
       "D Series": "D Serisi",
       "HWT-IVS1800": "HWT-IVS1800",
       "Incidents Detection": "Olay Tespiti",
       "HOLOWITS": "HOLOWITS",
       "HOLOWITS SELECTED": "HOLOWITS Önerisi",
       "OTHER": "Diğerleri",
       "Vehicle Detection": "Araç Algılama",
      },
      details: {
        "update tips": "Mevcut değil, lütfen güncellemeyi bekleyin..",
        "Service Provider": "Servis sağlayıcı",
        "To contact service provider": "Servis sağlayıcıyla iletişime geçmek için",
        "Service Provider Profile": "Servis Sağlayıcı Profili",
        "tips": "Sayfadaki açıklamalar, test sonuçlarına göre hizmet satıcıları tarafından sağlanır. Gerçek etkiler, uygulama senaryolarına bağlı olarak değişebilir. Kapsamlı ürün özellikleri hakkında ayrıntılar için mağaza operasyon ekibiyle iletişime geçin.",
        "tags": {
          "Product Details": "Ürün Detayları",
          "Associated Product": "İlişkili Ürün",
          "Customer Case": "Müşteri Durumu",
          "Customer Evaluation": "Müşteri Değerlendirmesi",
          "Support": "Destek",
        },
        smallTags: {
          "Parameters": "Parametreler",
          "Introduction": "Tanıtım",
          "Version": "Versiyon",
          "Highlights": "Öne Çıkanlar",
          "Scenario Requirements": "Senaryo Gereksinimleri",
          "Graphic presentation": "Grafik sunum",
          "Solution with this algorithm": "Algoritma ile çözüm",
          "Supporting Applications": "Destekleyici Uygulamalar",
          "User Manual": "Kullanım Kılavuzu",
        }
      }
     }
  };
  