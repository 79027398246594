export default {
  common: {
    querySpecifications: 'Query technical specifications',
    login: 'Sign In',
    logout: 'Sign Out',
    Home: 'Home',
    ProductCenter: 'Product Center',
    WhatIsaSoftwareDefinedCamera: 'What Is a Software-Defined Camera',
    ProductDetail: 'Product Detail',
    Solutions: 'Solutions',
    SolutionsDetail: 'Solutions Detail',
    Products: 'Products',
    Tools: 'Tools',
    Support: 'Support',
    Documentation: 'Documentation',
    news: 'News Center',
    newsDetail: 'News Detail',
    forPartners: 'For Partners',
    activity:'Activity',
    SoftwareDefinedCamera: 'Software-Defined Camera',
    IntelligentVisionSystem: 'IVS',
    Accessory: 'Accessory',
    ContactUs: 'Contact Us',
    NewsRoom: 'News Room',
    NewsRoomDetail: 'News Room Detail',
    Partner: 'Partner',
    Resources: 'Resources',
    ResourceCenter: 'Resource Center',
    VideoLibrary: 'Video Library',
    AftersalesSupport: 'Aftersales Support',
    Aftersales: 'Aftersales Q&A',
    Privacy: 'Privacy',
    TermsOfUse: 'Terms of use',
    clear:"Clear",
    RefineBy: 'Refine By',
    placeholder: 'Enter the model, name, or keyword',
    Favorites: 'Favorites',
    Compare: 'Compare',
    More: 'More',
    all: 'All',
    enter: 'Enter the model, name, or keyword',
    collapse: 'Collapse',
    expand: 'Expand',
    confirm:"Confirm",
    cancel:"Cancel",
    channel:"Channel",
  },
  login: {
    title: 'Sign In with User ID',
    email: 'Email',
    pwd: 'Password',
    changePwd: 'Change Password',
    forgetPwd: 'Forget Password',
    register: 'Register',
    rulesEmail: 'Email is invalid',
  },
  //导航栏
  navbar: {
    aboutTitle: 'ABOUT',
    about: 'ABOUT HOLOWITS',
    products: 'PRODUCTS',
    productCenter: 'PRODUCT CENTER',
    technicalSupport: 'SUPPORT',
    FAQ:'FAQ',
    solution: 'SOLUTIONS',
    documentation: 'DOCUMENTATION',
    support: 'SUPPORT',
    certificate: 'Certificate',
    pocDemo: 'PoC Demo',
    news: 'NEWS ROOM',
    events:'EVENTS',
    tools: 'TOOLS',
    partners: 'PARTNERS',
    resources: 'RESOURCES',
    forPartners: 'PARTNER DEVELOPMENT',
    forCustomers: 'FOR CUSTOMERS',
    channel:"PARTNER MAP",
    videoLibrary: "VIDEO",
    contact: "CONTACT US",
    algorithmstore: "ALGORITHM STORE",
    terms: "POLICIES, TERMS, CONDITIONS",
    warrantypolicy: "WARRANTYPOLICY",
    firmware: "FIRMWARE",
    software: "SOFTWARE",
    becomeAPartner: 'BECOME A PARTNER',
    dealRegistration: 'Partner Deal Registration',
    training: 'e-Learning',
    knowledge: 'Knowledge',
    successfulCases: 'Success Stories'
  },
  home: {
    'whatIsNew': "What's New",
    'ProductSpotlight': 'Product Spotlight',
    'Events&News': 'Events & News',
    'learnMore': 'Learn More'
  },
  //合作伙伴
  partners:{
    banner:{
      title:'HOLOWITS Certified',
      summery:'Intelligent Vision Specialist',
    },
    introduction:{
      title:'Introduction to Online Classes',
      desc:'This course introduces a full series of HOLOWITS software-defined cameras (SDCs), network video recorders (NVRs), intelligent Micro Edge devices, and solutions, and provides you with various learning materials and exams covering pre-sales and after-sales knowledge. You can obtain the certification of HOLOWITS Certified Intelligent Vision Specialist after passing the exams.',
    },
    course:{
      title:'Course Value',
      value:'This course will help you learn about the highlights of HOLOWITS product and solutions, master pre-sales skills, and provide customers with professional post-sales services.',
    },
    certification:{
      title:'Value of Certification',
      value:'This certificate can give you competitive edges in the market, showcase your expertise in HOLOWITS products, solutions, and after-sales services,and let customers and employers easily identify your professional capabilities.',
    },
    contents:[
      {text: 'HOLOWITS SDC Technical Main Slides'},
      {text: 'HOLOWITS Intelligent Micro Edge Technical Main Slides'},
      {text: 'HOLOWITS HWT-NVR800 Technical Main Slides'},
      {text: 'HOLOWITS Accessory Configuration Guide'},
      {text: 'HOLOWITS Intelligent Retail Solution'},
      {text: 'HOLOWITS Intelligent Campus Solution'},
      {text: 'HOLOWITS Intelligent Grid Solution'},
      {text: 'HOLOWITS Intelligent Transportation Solution'},
      {text: 'Quick Guide to Target Capture Camera Site Survey and Commissioning'},
      {text: 'Quick Guide to ePolice Camera Site Survey and Commissioning'},
      {text: 'HOLOWITS HWT-NVR800 Installation and Commissioning'},
      {text: 'HOLOWITS HWT-IVS1800 Installation and Commissioning'},
      {text: 'HOLOWITS A series Camera and XVR Introduction'},
      {text: 'HOLOWITS APP Operation Manual'},
      {text: 'HOLOWITS E Series Camera Introduction'},
    ],
    learningGuide:{
      title:'Learning Guide',
      desc:'To pass the exams, you should meticulously study related materials and videos. After obtaining the certification, you must update the certificate every two years.',
      text:'Registration is required for the first login',
      registerCode:'Registration code: ',
      registerValue:'UJIQ-RVH-PQW',
      btnValue:'Start Exam',
    },
  },
  //关于Holowits
  aboutHWT:{
    title:'About HOLOWITS',
    desc:'HOLOWITS is a global provider of intelligent Internet of Things (AIoT) products and solutions based on multi-dimensional awareness technologies, and integrates a broad range of capabilities such as R&D, production, distribution, sales and service capabilities. HOLOWITS is endowed with intelligent sensing technologies, a full-fledged industry chain, and powerful technical support capabilities. Responding to the ever-growing global demand for intelligence, HOLOWITS is committed to developing AI Camera (AIC), Video Intelligent Platform (VIP), as well as cloud services. These offerings can be applied in a wide variety of scenarios such as intelligent city, intelligent transportation, intelligent energy, intelligent manufacturing, intelligent campus, and intelligent retail. We aim to work together to build an intelligent world with all things sensing. To achieve this, we are devoted to providing safe, reliable, easy-to-use, and outstanding products and solutions for the global market.',
  },
  // 产品中心
  products: {
    productTypes: 'Product Type',
    sort: 'Sort',
    overAll: 'Overall',
    hot: 'Hot',
    latest: 'New',
    listPrice: 'List Price',
    preSales: 'Pre-Sales',
    model: 'Model',
    processor: 'Processor',
    memory: 'Memory',
    computingPower: 'Computing',
    series: '',
    form: ''
  },
  // 产品详情
  productDetail: {
    productDetail: 'Details',
    accessoryDetail: 'accessoryDetails',
    specifications: 'Specifications',
    certification: 'Certification',
    introduction: 'Introduction',
    download: 'Download',
    documentation: 'Documentation',
    huaweiCcsc: 'imgs/product_detail/mapen.png',
    clickHere: 'Click Here',
    dataSheet: 'Datasheet',
    afterSalesDocuments: 'After-Sales Documents',
    copy: 'Copy',
    reportError: 'Report Error',
    description: 'Description',
    describeError: 'Please describe the error you found.',
    picture: 'Picture',
    onlyJpg: 'Only the .jpg format is supported.',
    attachment: 'Attachment',
    selectFile: 'Select File',
    anonymous: 'Anonymous',
    submit: 'Submit',
    accessory: 'Accessory',
    technicalSpec: 'Technical Spec',
    dimensions: 'Dimensions',
    remarks: 'Remarks',
    optionalFitting: 'Optional Fitting',
    favorites: 'Favorite',
    keySpec: 'Key Spec',
    viewAll: 'Log In to View All',
    clicktoLoad: 'Click here to download',
    completeSpecificationList: 'Complete Specification List',
  },
  // 技术支持
  support: {
    wahtDoYouNeedHelpWith: 'What do you need help with?',
    title: 'Welcome to HOLOWITS Technical Support',
    explain: 'HOLOWITS Technical Support provides first-class support for your HOLOWITS products and solutions. Our online support services and engineers around the world are ready to help you anytime, anywhere.',
    documentation: 'Documentation',
    softwareDownload: 'Software Download',
    video: 'Video',
    searchPlaceholder: 'Enter keyword',
  },
  contact: {
    theme: 'Need Technical Support?',
    content: 'You are advised to contact the responsible HOLOWITS national distributor in your country or region for technical support.',
    tip1: 'To contact HOLOWITS distributors and resellers',
    tip2: 'To contact HOLOWITS for technical support, send emails to',
    tip3: 'To contact HOLOWITS for recruitment, send emails to',
    button1: 'Click here',
    button2: 'support@holowits.com',
    button3: 'hr@holowits.com',
    title: '',
    explain: ''
  },
    // 渠道中心
  channel: {
    partnerName: 'Partner Name',
    countryOrRegion: 'Country/Region',
    partnerType: 'PartnerType',
    searchPlaceholder:"Search in Partner List",
    website: 'Web Site',
    email: 'Email',
    phone: 'Phone',
    address: 'Address'
  },
  // 新闻中心
  news: {
    searchPlaceholder: 'Enter keyword',
    search: 'Search',
  },
  // 安防工具
  securityTools: {
    cameradetectionDistance: 'Calculate Camera\'s Detection Distance',
    disable: 'Disable',
    enable: 'Enable',
    videoResolution: 'Video Resolution',
    encodingBitRate: 'Encoding Bit Rate',
    NumberOfNVRs: 'Number of NVRs',
    NumberOfSDCs: 'Number of SDCs',
    microCheckpoint: 'Micro checkpoint',
    ePoliceCheckpoint: 'ePolice/Checkpoint',
    targetDetection: 'Target detection/recognition',
    calculateBandwidth: 'Calculate Bandwidth',
    calculateRc: 'Calculate Recording Capacity',
    NVRAccess: 'NVR Access',
    ipTools: 'IP Video System Design Tool',
    md5: 'Md5Checker',
    //提示信息
    microResolutionTips: 'In micro checkpoint mode, it is recommended that the license plate resolution be at least 80 pixels.',
    microInstallationTips: 'In lane scenarios, it is recommended that the installation height range from 4.5 m to 6 m. At campus entrances and exits, it is recommended that the installation height range from 1.3 m to 1.5 m. You can set the installation height based on the site requirements.',
    ePoliceResolutionTips: 'In ePolice mode, the license plate resolution must be greater than or equal to 100 pixels. In checkpoint mode, if only license plates need to be captured, the license plate resolution must be greater than or equal to 100 pixels. If targets inside vehicles also need to be captured, the license plate resolution must be greater than or equal to 180 pixels. ',
    ePoliceInstallationTips: 'The recommended installation height ranges from 5.5 m to 6.5 m.',
    targetResolutionTips: 'Target detection: For short-focus (less than 12 mm) cameras, the recommended target resolution is at least 80 pixels. For medium- and long-focus (12 mm or more) cameras, the recommended target resolution is at least 120 pixels. Target recognition: For short-focus (less than 12 mm) cameras, the recommended target recognition is at least 110 pixels. For medium- and long-focus (12 mm or more) cameras, the recommended target resolution is at least 120 pixels.',
    targetInstallationTips: 'Target detection: For cameras with a fixed focal length of 2.8 mm or 3.6 mm, the recommended installation height ranges from 2 m to 2.5 m. For cameras with a fixed focal length of 6 mm, the recommended installation height ranges from 2 m to 3 m. For cameras with other focal lengths, the recommended installation height ranges from 2.5 m to 3.5 m. Target recognition: For short-focus cameras, the recommended installation height ranges from 2.5 m to 3 m. For long-focus cameras, the recommended installation height ranges from 2.5 m to 3.5 m.',
  },

  // 提示信息
  tipInfo: {
    exportFailure: 'Export failure.',
    addProToComp: 'Add the product to compare.',
    selectLeastTwo: 'Select at least two products for comparison.',
    selectMaximum: 'You can select a maximum of four products for comparison.',
    copySuccess: 'Copy success.',
    noData: 'No data.',
    selectOther: 'The selected device is no data. Please select another device.',
    onlyJpg: 'Only JPG images are supported.',
    exeNoUpload: 'EXE files cannot be uploaded.',
    problemNotEmpty: 'The problem description cannot be empty.',
    submitSuccess: 'Submitted successfully.',
    submitFailed: 'Submission failed. Try again later.',
    loginSuccess: 'Login success',
    errorPwd:'The two passwords are inconsistent',
    confirmLogout:'Confirm to exit the current account?',
    tooManyRequest:'Getting the authentication code is too frequent. Please try again in 3 minute',
    getCodeImageFailed:'Failed to get image verification code',
  },
  technologySupport: {
    support: 'Support',
  },
  // 文档中心
  documentation: {
    searchPlaceholder: 'Enter the keyword',
    search: 'Search',
    productFilter: 'Product',
    solutionFilter: 'Solution',
    distributionFilter: 'Distribution',
    typeFilter: 'Scenarios',
    languageFilter: 'Language',
    formatFilter: 'Format',
    publishDate: 'Sort by release date',
    downloadTimes: 'Sort by download count',
    hotDoc: 'Hot Document',
    result: 'Result',
    download: 'Download',
    share: 'Share',
    batDownload: 'Batch Download',
    checkAll: 'Check All',
    paginalNumber: 'Paginal Number',
  },
  // 视频中心
  videoLibrary: {
    title: 'Video Library',
    searchVideo: 'Search video',
    all: 'All',
    branding: 'Branding',
    products: 'Products',
    solutions: 'Solutions',
    technicalSupport: 'Technical Support',
    training: 'Training',
    hOLOWITSClub: 'HOLOWITS Club',
    algorithm: 'Algorithm',
    technologies: 'Technologies'
  },
  faq: {
    faq: 'FAQ',
    Products: 'Products',
    Solutions: 'Solutions',
    Partners: 'Partners',
    Marketing: 'Marketing',
    Others: 'Others',
    placeholder: 'leave blank and you get all FAQs',
    help: 'How to use FAQ search'
  },
  learnRegister: {
    pageTitle: "HOLOWITS Certified Specialist Exam Registration",
    pageDesc1: "Please fill in the form below.",
    pageDesc2: "After verification, we will send email to you.",
    hint1: "Please prove that you are an employee of your company (You can upload your staff card or business card)",
    hint2: "Please upload your company business license (Not required)",
    required: "This field is required.",
    upload: "upload",
    successPanelTitle: "Submitted successfully",
    successPanelText: "The application has been submitted and the administrator will contact you via email.\nIf your company want to become our Certified Tier 2 partner, please click here to register. After one employee of your company passes HOLOWITS Certified Specialist Exam, your company can become our Certified Tier 2 partner and also can be shown our website.",
    inputPlaceholder: "Please briefly describe why you attend our HOLOWITS specialist exam"
  },
  register: {
    register: "Register",
    email: "Please input your Employee Email",
    companyName: "Company Full Name",
    name: "Your Name",
    country: "Country/Region",
    description: "Please describe the Partner Type of your company and HOLOWITS",
    code: "Verification Code",
    cancel: "Cancel",
    submit: "Submit",
    successPanelTitle: "Submitted successfully",
    successPanelText: "The application has been submitted and we will contact you via email.",
    back: "Back",
    rules: {
      email: "Email cannot be empty.",
      companyName: "Name cannot be empty.",
      name: "Name cannot be empty.",
      country: "CountryOrRegion cannot be empty.",
      description: "description cannot be empty.",
      code: "code cannot be empty."
    }
  },
  bind: {
    successPanelText: "The application has been submitted and we will contact you via email.",
  },
  reseller: {
    pageTitle: "BECOME A PARTNER",
    companyName: "Company Name",
    webSite: "Official Website URL",
    region: "Region",
    coopCountry: "Country/Region",
    preSales: "Email for Pre-sales support",
    postSales: "Email for Post-sales support",
    marketing: "Email for marketing support",
    email: "Email to contact you",
    companyEmail: "Email to be registered as your company account",
    phone: "Telephone Number for Pre-sales support",
    address: "Company Address",
    partnerType: "Partner Type",
    uploadLog: "Upload a high resolution graphic of your company logo (jpg. or png.)",
    upload: "Upload",
    downlaod: "Download agreement template",
    businessLicense: "Upload your company business license (File on larger than 50M) (Support pdf. png. jpg. jpeg. webp. format)",
    agreeFieldText: "I authorize HOLOWITS to list my company on HOLOWITS websites.",
    successPanelTitle: "Submitted successfully",
    successPanelText: "The application has been submitted and the administrator will contact you via email.",
    distributor: "Distributor",
    uploadTips: "Please download and print the file, fill in the relevant information and sign by hand, and then upload the scanned copy of the file.",
  },
  training: {
    documentation: "Documentation",
  },
  contactUs: {
    contactUsPricingInfo:'Contact Us Pricing Info',
    contactUs: 'Contact Us',
    options: {
      pricingInfo: 'Pricing/Info',
      serviceRequests: 'Service Requests',
    },
    pricingInfo: {
      Title: 'Contact Us',
      Desc1: 'Please fill in the form below.',
      Desc2: 'We will contact you soon.',
      Mandatory: 'Mandatory',
      FirstName: 'First Name',
      LastName: 'Last Name',
      Email: 'Email',
      Telephone: 'Telephone',
      CountryRegion: 'Country/Region',
      Partner: 'Partner',
      PreferLanguage: 'Language',
      preference: 'Pleverye Select Language Preference',
      Company: 'Company',
      company_placeholder: 'Please input company full name',
      Industry: 'Industry',
      JobTitle: 'JobTitle',
      FavoriteProducts: 'FavoriteProducts',
      Budget: 'Budget',
      BrieflyPlaceholder: 'Briefly outline your project requirements. ',
      ConfirmText: 'I agree to receive information about products, solutions, services, and offerings from HOLOWITS and HOLOWITS authorized partners. I understand that I can unsubscribe at any time.',
      Yes: 'Yes',
      No: 'No',
      AgreeText: 'I understand and agree to the use and transfer of my personal information by HOLOWITS according to HOLOWITS’ ',
      PrivacyPolicy: 'Privacy Policy',
      publictiy: 'After one employee of your company passes HOLOWITS Certified Specialist Exam, your company can become our Certified Tier 2 Partner and also can be shown on our website.',
      TermsOfUse: 'Terms of Use',
      And: 'and',
      placeholder: 'Please input',
      submit: 'Submit',
      fieldRequired: 'This field is required.',
      emailValidator: {
        'at-error': 'An email address must contain a &quot;@&quot; symbol.',
        'account-letter-forbidden': 'The email name (example: Adam.Smith@abc.com) contains invalid characters.',
        'prevDot-error': 'The email name (example: Adam.Smith@abc.com) must not start or end with a dot or period (“.”).',
        'double-dot-error:': 'Two successive dots or periods (“.”) together is invalid.',
        'domain-error': 'The domain (example: Adam.Smith@abc.com) must contain at least one domain name.',
        'length-error': 'An email address must not exceed 254 characters.',
        'nextLetter-forbidden': 'The domain (example: Adam.Smith@abc.com) contains invalid characters.',
        'nextLine-error': 'The domain (example: Adam.Smith@abc.com) must not start or end with a hyphen (“-“).',
        'account-length-error': 'An email name (example: Adam.Smith@abc.com) must not exceed 63 characters.',
      },
      phoneFormatError: 'Example : Area Code(3-6 DIGITALS, eg.0066)Phone Number'
    },
  },
  algorithm: {
    label: "ALGORITHM STORE",
    pageTitle: "HOLOWITS Algorithm Store",
    navOptionTag: "Intelligent Algorithm",
    recommendation: "Related products recommendation",
    selectorItemTitle: {
     "Product Application Scenario": "Product Application Scenario",
     "Algorithm Classification": "Algorithm Classification",
     "Applicable Platform": "Applicable Platform",
     "Algorithm type": "Algorithm type"
    },
    tag: {
     "All": "All",
     "Transportation": "Transportation",
     "Government": "Government",
     "Finance": "Finance",
     "Energy": "Energy",
     "Public Security": "Public Security",
     "Personnel Detection": "Personnel Detection",
     "Intelligent Transportation": "Intelligent Transportation",
     "Text Detection": "Text Detection",
     "Behavior Analysis": "Behavior Analysis",
     "Voice Detection": "Voice Detection",
     "Video Analysis": "Video Analysis",
     "Perimeter Events": "Perimeter Events",
     "Event Detection": "Event Detection",
     "Object Detection": "Object Detection",
     "Detection Analysis": "Detection Analysis",
     "SDC": "SDC",
     "HWT-IVS1800": "HWT-IVS1800",
     "HOLOWITS": "HOLOWITS",
     "HOLOWITS SELECTED": "HOLOWITS SELECTED",
     "OTHER": "OTHER"
    },
    label: {
     "Subway": "Subway",
     "Highway": "Highway",
     "Railway": "Railway",
     "Airport": "Airport",
     "Public Transport": "Public Transport",
     "Digital Government": "Digital Government",
     "Bank": "Bank",
     "Insurance": "Insurance",
     "Wind Power": "Wind Power",
     "Solar Energy": "Solar Energy",
     "Electric Power": "Electric Power",
     "Gas": "Gas",
     "Water Conservancy": "Water Conservancy",
     "Petroleum": "Petroleum",
     "Forest": "Forest",
     "City Management": "City Management",
     "Fire Detection": "Fire Detection",
     "Manufacturing": "Manufacturing",
     "Agriculture": "Agriculture",
     "Retail": "Retail",
     "Logistics": "Logistics",
     "Service": "Service",
     "Medical Treatment": "Medical Treatment",
     "Catering Industry": "Catering Industry",
     "Tourism": "Tourism",
     "Education": "Education",
     "Campus": "Campus",
     "Family": "Family",
     "Internet of Things(IoT)": "Internet of Things(IoT)",
     "Target Recognition": "Target Recognition",
     "Personnel Attribute Analysis": "Personnel Attribute Analysis",
     "Overall Target Detection": "Overall Target Detection",
     "License Plate detection": "License Plate detection",
     "Vehicle Attribute Detection": "Vehicle Attribute Detection",
     "Non-standard Vehicle Inspection": "Non-standard Vehicle Inspection",
     "Illegal Detection": "Illegal Detection",
     "Vehicle Detection": "Vehicle Detection",
     "Omni-data Structuring": "Omni-data Structuring",
     "OCR": "OCR",
     "Regulation Breach Detection": "Regulation Breach Detection",
     "Crowd Analysis": "Crowd Analysis",
     "Voice Synthesis": "Voice Synthesis",
     "Voice Identification": "Voice Identification",
     "Video Abstract": "Video Abstract",
     "Perimeter Intrusion": "Perimeter Intrusion",
     "Water Pollution Detection": "Water Pollution Detection",
     "Carrying Dangerous Objects": "Carrying Dangerous Objects",
     "Carrying Prohibited Items": "Carrying Prohibited Items",
     "Construction Machinery Detection": "Construction Machinery Detection",
     "Foreign Matter Detection": "Foreign Matter Detection",
     "Wearing Detection": "Wearing Detection",
     "Equipment Status Detection": "Equipment Status Detection",
     "Instrumentation Detection": "Instrumentation Detection",
     "Defect Detection": "Defect Detection",
     "Remote Sensing": "Remote Sensing",
     "Robot": "Robot",
     "X Series": "X Series",
     "M Series": "M Series",
     "C Series": "C Series",
     "D Series": "D Series",
     "HWT-IVS1800": "HWT-IVS1800",
     "Incidents Detection": "Incidents Detection",
     "HOLOWITS": "HOLOWITS",
     "HOLOWITS SELECTED": "HOLOWITS SELECTED",
     "OTHER": "OTHER",
     "Vehicle Detection": "Vehicle Detection",
     "Video Synopsis": "Video Synopsis",
    },
    details: {
      "update tips": "Not available, please wait for the update.",
      "Service Provider": "Service Provider",
      "To contact service provider": "To contact service provider",
      "Service Provider Profile": "Service Provider Profile",
      "tips": "Descriptions on the page are provided by service vendors based on their test results. Actual effects may vary depending on application scenarios. For details about comprehensive product capabilities, contact the store operation team.",
      "tags": {
        "Product Details": "Product Details",
        "Associated Product": "Associated Product",
        "Customer Case": "Customer Case",
        "Customer Evaluation": "Customer Evaluation",
        "Support": "Support"
      },
      smallTags: {
        "Parameters": "Parameters",
        "Introduction": "Introduction",
        "Version": "Version",
        "Highlights": "Highlights",
        "Scenario Requirements": "Scenario Requirements",
        "Graphic presentation": "Graphic presentation",
        "Solution with this algorithm": "Solution with this algorithm",
        "Supporting Applications": "Supporting Applications",
        "User Manual": "User Manual"
      }
    }
   },
   terms: {
    title1: "Technical Support",
    title2: "HOLOWITS Product Warranty Policy",
    jumpPrompt: "Find the warranty policy",
  },
  specialist: {
    register: "Register",
    startLearning: "Start Learning"
  },
  csp: {
    title1: "Partner Type",
    title2: "Language", 
    countLabel: "Results Found",
    placeholder: "Enter Keyword",
  },
  tools: {
    cameras: "Cameras",
    scenario: "Scenario",
    videoResolution: "Video Resolution",
    encodingBitRate: "Encoding Bit Rate (Mbit/s)",
    streamChannels: "Stream Channels",
    packetLossRate: "Packet Loss Rate",
    videoBufferingChannels: "Video Buffering Channels",
    singleImageSize: "Single Image Size",
    imagesUploadedPerSecond: "Images Uploaded Per Second",
    reservedBandwidthCoefficient: "Reserved Bandwidth Coefficient",
    uploadVia: "Upload via FTP/SFTP",
    disable: "Disable",
    enable: "Enable",
    calculate: "Calculate",
    reset: "Reset",
    basis: "Basis",
    basisVideo: "Video Resolution",
    basisiRate: "Encoding Bit Rate",
    encodingFormat: "Encoding Format",
    coefficient: "Bit rate fluctuation coefficient",
    storagePeriod: "Storage Period (Day)",
    result: "Result",
    forReferenceOnly: "for Reference Only",
    bandwidthRequired: "Bandwidth Required",
    about: "About",
    viewFormula: "View Formula",
    capacityRequired: "Capacity Required",
    calculationObject: "Calculation Object",
    obj1: "Number of NVRs",
    obj2: "Number of SDCs",
    model: "NVR Model",
    microCheckpoint: "Micro checkpoint",
    ePolic: "ePolic/Checkpoint",
    recognition: "Target detection/recognition",
    resolutionRequirement: "Resolution Requirement (px)",
    sensorSize: "Sensor Size",
    resolution : "Resolution (w)",
    focal: "Focal Length (mm)",
    installationHeight: "Installation Height (m)",
    numberOfRrequired: "Number of required NVRs",
    resultLabel1: "Detection Distance (d)",
    tilt: "Tilt Angle (α)",
    diagram: "Diagram",
    designTool: {
      title1: "IP Video System Design Tool",
      title2: "HOLOWITS cameras have been added into the database",
      title3: "With the IP Video System Design Tool you can:",
      small1: "Increase efficiency of security systems while lowering costs and finding the best camera locations.",
      small2: "Calculate precise camera lens and sensors requirements easily to achieve desired pixel density (PPM/PPF).",
      small3: "Visually show identification, recognition, observation, detection and monitoring zones of each camera on a site plan.",
      small4: "Import floor plans in JPEG, PNG, PDF, TIFF or AutoCAD DWG drawings (Pro) to jump start your designs.",
      small5: "Database of 10,000+ camera models, with pre-configured parameters for easy designing.",
      small6: "Estimate network bandwidth and storage needs for video archive configuration.",
      small7: "Plan cable paths and calculate cable lengths.",
      small8: "Export designs to include with proposals or work orders.",
      title4: "Click the link to download:",
      title5: "In order to update the camera database, please click ?/Check for new versions/Synchronize after installation."
    },
    md5: {
      title1: 'Md5Checker',
      introduce1: 'Md5Checker is a free, faster, lightweight and easy-to-use tool to manage, calculate and verify MD5 checksum of multiple files/folders. ',
      introduce2: 'You can use MD5Checker to verify the firmware you download on HOLOWITS website.',
      small1: 'Calculate and display MD5 checksum of multiple files at one time.',
      small2: 'Use MD5 checksum to fleetly verify whether files have been changed.',
      small3: 'Load, save, add, remove and update MD5 checksum conveniently.',
      small4: 'It is about 300 KB and does not require any installation (portable).',
      title2: 'What is MD5 checksum?',
      introduce3: 'MD5 checksum (MD5 hash) is a type of digests of files.',
      introduce4: 'different if any modification has been made to the file, even a byte.',
      downloadTips: 'Click the link to download:'
    }
  },
  firm: {
    label: "Firmware Download",
  },
  soft: {
    label: "Software Download",
  },
  newsRoom: {
    label: "NEWS ROOM",
    describe: "News and Announcements, Customer Wins, and More",
    learnMore: "LEARN MORE"
  },
  dr: {
    label: "DEAL REGISTRATION",
    subject: "Deal List",
    title: "Search Condition",
    state1: "Approved",
    state2: "Disapproved",
    state3: "To be Approved",
    state4: "Closed",
    state5: "Unclosed",
    state6: "Pending",
    state7: "Done",
    state8: "Cancelled",
    column1: "Project Name",
    column2: "Company Name (Applicant)",
    column3: "Expected Date of Order",
    column4: "Approval Status",
    column5: "Status",
    column6: "Expiration Date",
    column7: "End Customers Name"
  },
  drDetails: {
    subject1: "Deal Info",
    title1_1: "Deal Business Type",
    title1_2: "Project Name",
    title1_3: "Company Name (Applicant)",
    title1_4: "Win Odds (%)",
    title1_5: "Expected Date of Order",
    title1_6: "Public Tender",
    title1_7: "Deal Registration Start From",
    title1_8: "Expiration Date",
    title1_9: "Deal Description",
    title1_10: "Deal Background",
    title1_11: "Channel Manger Email",
    subject2: "Project Progress and Difficulties for help",
    title2_1: "Operation",
    title2_2: "Project Progress",
    title2_3: "Difficulties for help",
    title2_4: "Created Date",
    subject3: "Competitors",
    title3_1: "Operation",
    title3_2: "Competitor Name",
    title3_3: "Competitive Product",
    subject4: "Products",
    title4_1: "Operation",
    title4_2: "Product Type",
    title4_3: "Series",
    title4_4: "Model",
    title4_5: "Estimated Quantity",
    subject5: "Trading Parties",
    title5_1: "End Customers",
    title5_2: "Product Type",
    title5_3: "End Customers Name",
    title5_4: "End Custimer Name(Native)",
    title5_5_1: "First Name",
    title5_5_2: "Country/Region",
    title5_6: "Last Name",
    title5_7: "State",
    title5_8: "Email",
    title5_9: "City",
    title5_10: "Office Phone",
    title5_11: "Town",
    title5_12: "Address",
    title5_13: "Web site",
    title5_14: "Postal Code",
    title5_15: "Phone NO.",
    title5_16: "Industry",
    title5_17: "Sub-industry",
    title5_18: "Distributor",
    title5_19: "Company Name",
    title5_20: "Address",
    title5_21: "Location Name",
    title5_22: "Postal Code",
    title5_23: "City",
    title5_24: "Tier 2 Partner (if have)",
    title5_25: "Company Name",
    title5_26: "Address",
    title5_27: "Location Name",
    title5_28: "Postal Code",
    title5_29: "City",
    subject6: "Partner Sales Team",
    title6_1: "Operation",
    title6_2: "MAIN",
    title6_3: "Partner",
    title6_4: "Company Name",
    title6_5: "Role",
    title6_6: "Name",
    title6_7: "Email",
    title6_8: "Office phone",
    tips1_1: "1) For the purpose of HOLOWITS's provision of related services to you, HOLOWITS will collect Your personal data (including name, email, etc.), the personal data will be stored on the servers of HOLOWITS TECHNOLOGIES PTE. LTD. (HOLOWITS) and its affiliates. It may be processed by HOLOWITS and the relevant third party. It may be transferred to the third party's country/region or to locations separate from those where HOLOWITS collects the information, and will be accessed, stored, and processed in the aforesaid locations .For more details about how HOLOWITS processes the data, please see the Privacy Statement. Privacy Statement. ",
    tips1_2: "2) If the data you enter contains the personal information of a third party, please inform the third party of the above and obtain their consent or authorization, too. ",
    link1: "Privacy Statement.",
    tips2_1: "Request to close the deal",
    tips2_2: "(If you select this option, you will not be able to modify or update the content of this deal after it is closed).",
    submit: "Submit",
  }
};
