<template>
  <div>
    <div class="contact-us-btn" @click="toPricingInfo">
        <i class="el-icon-phone-outline"></i>
    </div>
    <!-- <div class="fixed-right">
      <el-button type="primary" @click="toPricingInfo">{{ $i18n.t('contactUs.contactUs') }}</el-button>
    </div> -->
    <!-- <transition name="el-fade-in-linear">
      <div v-show="visible" class="contact-us-modal-mask" @mousewheel.stop.prevent>
        <div class="contact-us-modal" @click.stop>
          <div class="contact-us-modal-title">
            <div class="contact-us-modal-title-text">{{ $i18n.t('contactUs.contactUs') }}</div>
            <div class="contact-us-modal-close" @click="closeModal"></div>
          </div>
          <div class="contact-us-modal-body">
            <el-row :gutter="10">
              <el-col :md="12" :xs="24">
                <div class="contact-us-option" @click="toPricingInfo">
                  <img class="contact-us-option-icon" src="@/assets/image/contactUs/icon_PricingInfo.png"
                       alt="Pricing/Info">
                  <div class="contact-us-option-text">{{ $i18n.t('contactUs.options.pricingInfo') }}</div>
                </div>
              </el-col>
              <el-col :md="12" :xs="24">
                <div class="contact-us-option" @click="toServiceRequests">
                  <img class="contact-us-option-icon" src="@/assets/image/contactUs/icon_ServiceRequests.png"
                       alt="Service Requests">
                  <div class="contact-us-option-text">{{ $i18n.t('contactUs.options.serviceRequests') }}</div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
  export default {
    name: 'ContactUs',
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      toPricingInfo() {
        this.closeModal();
        this.$router.push({
          path: '/contactus',
        });
      },
      toServiceRequests() {
        this.closeModal();
        this.$router.push({
          path: '/contactUs/serviceRequests',
        });
      },
      openModal() {
        this.visible = true;
      },
      closeModal() {
        this.visible = false;
      },
    },
  };
</script>

<style scoped lang="less">
  .contact-us-btn {
      position: fixed;
      background-color: #FFFFFF;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      color: #c7000b;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      box-shadow: 0 0 6px #0000001f;
      cursor: pointer;
      z-index: 5;
      right: 20px;
      bottom: 100px;
      &:hover {
          background-color: #F2F6FC;
      }
  }

  .fixed-right {
    position: fixed;
    right: 20px;
    bottom: 100px;
    z-index: 30;
  }

  .contact-us-modal-mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, .5);
    z-index: 100000;
  }

  .contact-us-modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 546px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.3);
    margin: 0 10px;

    .contact-us-modal-title {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 50px;
      padding-right: 24px;

      .contact-us-modal-title-text {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
      }

      .contact-us-modal-close {
        background-image: url("../../assets/image/contactUs/icon_close.png");
        background-size: 12px;
        background-position: center;
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        cursor: pointer;

        &:active {
          opacity: .7;
        }
      }
    }

    .contact-us-modal-body {
      padding: 30px 52px 20px;
      background: #F8F8F8;
    }

    .contact-us-option {
      height: 74px;
      background: #FFFFFF;
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 15px;
      cursor: pointer;
      margin-bottom: 20px;
      transition: .1s ease-out;
      user-select: none;
      white-space: nowrap;
      min-width: 214px;

      &:hover {
        background: #f0f0f0;
      }

      &:active {
        background: #f3f3f3;
      }

      .contact-us-option-icon {
        width: 44px;
        height: 44px;
        object-fit: cover;
        margin-right: 7px;
        user-select: none;
        pointer-events: none;
      }

      .contact-us-option-text {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
</style>
