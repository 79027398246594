export default {
  common: {
    querySpecifications: 'Solicitação de especificações técnicas',
    login: 'Sign In',
    logout: 'Sign Out',
    Home: 'Home',
    ProductCenter: 'Centro de Produtos',
    WhatIsaSoftwareDefinedCamera: 'O que é uma câmera definida por software',
    ProductDetail: 'Detalhes do Produto',
    Solutions: 'Soluções',
    SolutionsDetail: 'Detalhes da Solução',
    Products: 'Produtos',
    Tools: 'Ferramentas',
    Support: 'Suporte',
    Documentation: 'Documentação',
    news: 'Novidades',
    newsDetail: 'Detalhes das Novidades',
    forPartners: 'Para Parceiros',
    activity:'Atividades',
    SoftwareDefinedCamera: 'Câmera definida por software',
    IntelligentVisionSystem: 'IVS',
    Accessory: 'Acessório',
    ContactUs: 'Contato',
    NewsRoom: 'Sala de Notícias',
    NewsRoomDetail: 'Detalhes da Sala de Notícias',
    Partner: 'Parceiros',
    Resources: 'Recursos',
    ResourceCenter: 'Centro de Recursos',
    VideoLibrary: 'Vídeos Disponíveis',
    AftersalesSupport: 'Suporte de pós-vendas',
    Aftersales: 'Pós-vendas Q&A',
    Privacy: 'Privacidade',
    TermsOfUse: 'Termos de Uso',
    clear:"Clear",
    RefineBy: 'Filtrar por',
    placeholder: 'Insira modelo, nome ou palavra-chave',
    Favorites: 'Favoritos',
    Compare: 'Compare',
    More: 'Mais',
    all: 'Todos',
    enter: 'Insira modelo, nome ou palavra-chave',
    collapse: 'Collapse',
    expand: 'Expandir',
    confirm:"Confirmar",
    cancel:"Cancelar",
    channel:"Canal",
  },
  //导航栏
  navbar: {
    aboutTitle: 'SOBRE',
    about: 'SOBRE HOLOWITS',
    products: 'PRODUTOS',
    productCenter: 'CENTRO DE PRODUTOS',
    technicalSupport: 'SUPORTE TÉCNICO',
    FAQ:'FAQ',
    solution: 'SOLUÇÕES',
    documentation: 'Documentação',
    support: 'SUPORTE',
    certificate: 'Certificado',
    pocDemo: 'PoC Demonstração',
    news: 'NEWS ROOM',
    events:'EVENTOS',
    tools: 'FERRAMENTAS',
    partners: 'PARCEIROS',
    resources: 'RECURSOS',
    forPartners: 'PARA PARCEIROS',
    forCustomers: 'PARA CONSUMIDORES',
    channel:"PARA CONSUMIDORES",
    videoLibrary: "VÍDEOS",
    contact: "FALE CONOSCO"
  },
  home: {
    'whatIsNew': 'O que há de novo',
    'ProductSpotlight': 'destaque do produto',
    'Events&News': 'Eventos & notícias',
    'learnMore': 'saiba mais'
  },
  //合作伙伴
  partners:{
    banner:{
      title:'HOLOWITS Certified',
      summery:'Intelligent Vision Specialist',
    },
    introduction:{
      title:'Introdução às aulas online',
      desc:'Este curso apresenta a linha de câmeras definidas por software (SDCs) da HOLOWITS, gravadores de vídeo em rede (NVRs), dispositivos inteligentes Micro Edge e soluções. Também fornece vários materiais de aprendizagem e exames que abordam temas de  pré-vendas e pós-venda. Você pode obter a certificação de Especialista em Visão Inteligente da HOLOWITS depois de passar nos exames.',
    },
    course:{
      title:'Valor do Curso',
      value:'Este curso ajudará você a conhecer as características principais dos produtos e soluções da HOLOWITS, dominar as habilidades de pré-venda e fornecer aos clientes serviços profissionais de pós-venda.',
    },
    certification:{
      title:'Valor da Certificação',
      value:'Está certificação pode oferecer vantagens competitivas no mercado, mostrar sua experiência em produtos, soluções e serviços de pós-venda da HOLOWITS e permitir que clientes e empregadores identifiquem facilmente suas capacidades profissionais.',
    },
    contents:[
      {text: 'HOLOWITS SDC Technical Main Slides'},
      {text: 'HOLOWITS Intelligent Micro Edge Technical Main Slides'},
      {text: 'HOLOWITS HWT-NVR800 Technical Main Slides'},
      {text: 'HOLOWITS Accessory Configuration Guide'},
      {text: 'HOLOWITS Intelligent Retail Solution'},
      {text: 'HOLOWITS Intelligent Campus Solution'},
      {text: 'HOLOWITS Intelligent Grid Solution'},
      {text: 'HOLOWITS Intelligent Transportation Solution'},
      {text: 'Quick Guide to Target Capture Camera Site Survey and Commissioning'},
      {text: 'Quick Guide to ePolice Camera Site Survey and Commissioning'},
      {text: 'HOLOWITS HWT-NVR800 Installation and Commissioning'},
      {text: 'HOLOWITS HWT-IVS1800 Installation and Commissioning'},
    ],
    learningGuide:{
      title:'Guia de Aprendizado',
      desc:'Para passar nos exames, você deve estudar cuidadosamente os materiais e vídeos relacionados. Depois de obter a certificação, você deve atualizar o certificado a cada dois anos.',
      text:'Registro é necessário no primeiro log in',
      registerCode:'Código de Registro: ',
      registerValue:'UJIQ-RVH-PQW',
      btnValue:'Comece a aprender',
    },
  },
  //关于Holowits
  aboutHWT:{
    title:'Sobre a HOLOWITS',
    desc:'A HOLOWITS é uma fornecedora global de produtos e soluções inteligentes de Internet das Coisas (IoT) baseados em tecnologias para percepção multidimensional e integra uma ampla gama de recursos, como P&D, produção, distribuição, vendas e suporte. A HOLOWITS possui tecnologias de visão inteligente, uma completa cadeia na indústria e grande capacidade de suporte técnico. Respondendo à crescente demanda global por inteligência visual, a HOLOWITS está comprometida com o desenvolvimento de câmeras definidas por software (SDC) habilitadas para IA, armazenamento de vídeo inteligente e plataformas de computação, bem como serviços em nuvem. Essas ofertas podem ser aplicadas em uma ampla variedade de cenários, como cidade inteligente, transporte inteligente, energia inteligente, fabricação inteligente, campus inteligente e varejo inteligente. Nosso objetivo é trabalhar juntos para construir um mundo inteligente com todo sensoriamento disponível. Para conseguir isso, estamos dedicados a fornecer produtos e soluções seguras, confiáveis, fáceis de usar e excelentes para o mercado global.',
  },
  // 产品中心
  products: {
    productTypes: 'Tipo de Produto',
    sort: 'Ordenar',
    overAll: 'Geral',
    hot: 'Hot',
    latest: 'Novo',
    listPrice: 'Lista de Preços',
    preSales: 'Pré-vendas',
    model: 'Modelo',
    processor: 'Processador',
    memory: 'Memória',
    computingPower: 'Computação',
    series: '',
    form: ''
  },
  // 产品详情
  productDetail: {
    productDetail: 'Detalhes',
    accessoryDetail: 'Detalhes',
    specifications: 'Especificações',
    certification: 'Certificação',
    introduction: 'Introdução',
    download: 'Download',
    documentation: 'Documentação',
    huaweiCcsc: 'imgs/product_detail/mapen.png',
    clickHere: 'Click Aqui',
    dataSheet: 'Catálogo',
    afterSalesDocuments: 'Material de pós-vendas',
    copy: 'Copiar',
    reportError: 'Reportar Erro',
    description: 'Descriçãp',
    describeError: 'Por favor, descreva o erro encontrado.',
    picture: 'Imagem',
    onlyJpg: 'Somente formato .jpg é suportado.',
    attachment: 'Anexo',
    selectFile: 'Selecionar arquivo',
    anonymous: 'Anônimo',
    submit: 'Enviar',
    accessory: 'Acessórios',
    technicalSpec: 'Especificação Técnica',
    dimensions: 'Dimensões',
    remarks: 'Remarks',
    optionalFitting: 'Encaixe opcional',
    favorites: 'Favoritos',
    keySpec: 'Especificações principais',
    viewAll: 'Se registre para ver tudo',
    clicktoLoad: 'Clique aqui para baixar',
    completeSpecificationList: 'Lista completa de especificações',
  },
  // 技术支持
  support: {
    wahtDoYouNeedHelpWith: 'Com o que você precisa de ajuda?',
    title: 'Bem-vindo ao suporte técnico da Holowits!',
    explain: 'O suporte técnico da HOLOWITS fornece suporte de primeira classe para seus produtos e soluções HOLOWITS. Nossos serviços de suporte on-line e engenheiros em todo o mundo estão prontos para ajudá-lo a qualquer hora, em qualquer lugar.',
    documentation: 'Documentação',
    softwareDownload: 'Baixar programa',
    video: 'Vídeo',
    searchPlaceholder: 'Insira palavra-chave',
  },
    // 渠道中心
  channel: {
    partnerName: 'Nome do parceiro',
    countryOrRegion: 'País/Região',
    partnerType: 'PartnerType',
    searchPlaceholder:"Busca na lista de parceiros",
    website: 'Web Site',
    email: 'Email',
    phone: 'Telefone',
    address: 'Endereço'
  },
  // 新闻中心
  news: {
    searchPlaceholder: 'Insira palavra-chave',
    search: 'Buscar',
  },
  // 安防工具
  securityTools: {
    cameradetectionDistance: 'Calcular a distâmcia de detecção da câmera',
    disable: 'Desabilitar',
    enable: 'Habilitar',
    videoResolution: 'Resolução de Vídeo',
    encodingBitRate: 'Encoding Bit Rate',
    NumberOfNVRs: 'Quantidade de NVRs',
    NumberOfSDCs: 'Quantidade de SDCs',
    microCheckpoint: 'Micro checkpoint',
    ePoliceCheckpoint: 'ePolice/Checkpoint',
    targetDetection: 'Detecção / Reconhecimento do alvo',
    calculateBandwidth: 'Cálculo de Bandwidth',
    calculateRc: 'Cálculo da capacidade de gravação',
    NVRAccess: 'Acesso ao NVR',
    //提示信息
    microResolutionTips: 'No modo micro checkpoint, é recomendado que a resolução da placa seja, no mínimo, de 80 pixels.',
    microInstallationTips: 'Em cenários de faixa de rodagem, recomenda-se que a altura de instalação varie entre 4,5m e 6m. Nas entradas e saídas de campus, recomenda-se que a altura de instalação varie de 1,3 m a 1,5 m. Você pode definir a altura de instalação com base nos requisitos do site.',
    ePoliceResolutionTips: 'No modo ePolice, a resolução da placa deve ser maior ou igual a 100 pixels. No modo de ponto de verificação, se apenas as placas precisarem ser capturadas, a resolução da placa deve ser maior ou igual a 100 pixels. Se os alvos dentro dos veículos também precisarem ser capturados, a resolução da placa deve ser maior ou igual a 180 pixels. ',
    ePoliceInstallationTips: 'A altura de instalação recomendada varia de 5,5 m a 6,5 m.',
    targetResolutionTips: 'Detecção do alvo: Para câmeras de foco curto (menos de 12 mm), a resolução de alvo recomendada é de pelo menos 80 pixels. Para câmeras de foco médio e longo (12 mm ou mais), a resolução do alvo recomendada é de pelo menos 120 pixels. Reconhecimento de alvo: Para câmeras de foco curto (menos de 12 mm), o reconhecimento de alvo recomendado é de pelo menos 110 pixels. Para câmeras de foco médio e longo (12 mm ou mais), a resolução recomendada do alvo é de pelo menos 120 pixels.',
    targetInstallationTips: 'Detecção de alvo: Para câmeras com distância focal fixa de 2,8 mm ou 3,6 mm, a altura de instalação recomendada varia de 2 m a 2,5 m. Para câmeras com uma distância focal fixa de 6 mm, a altura de instalação recomendada varia de 2 m a 3 m. Para câmeras com outras distâncias focais, a altura de instalação recomendada varia de 2,5 m a 3,5 m. Reconhecimento de alvos: Para câmeras de foco curto, a altura de instalação recomendada varia de 2,5 m a 3 m. Para câmeras de foco longo, a altura de instalação recomendada varia de 2,5 m a 3,5 m.',
  },

  // 提示信息
  tipInfo: {
    exportFailure: 'Falha na exportação.',
    addProToComp: 'Adicione produto para comparar.',
    selectLeastTwo: 'Selecione pelo menos dois produtos para comparação.',
    selectMaximum: 'Você pode selecionar até quatro produtos para comparar.',
    copySuccess: 'Copiado com sucesso.',
    noData: 'Sem dados.',
    selectOther: 'O dispositivo selecionado não tem dados. Por favor, selecione outro dispositivo.',
    onlyJpg: 'Somente imagens JPG são suportadas.',
    exeNoUpload: 'Arquivos EXE não podem ser carregados.',
    problemNotEmpty: 'A descrição do problema não pode estar vazia.',
    submitSuccess: 'Enviado com sucesso.',
    submitFailed: 'Envio falhou. Tente do novo mais tarde.',
    loginSuccess: 'Login bem sucedido',
    errorPwd:'As duas senhas são inconsistentes',
    confirmLogout:'Confirma sair do usuário atual?',
    tooManyRequest:'Requisição de código de autenticação muito frequente. Por favor tente novamente em três minutos',
    getCodeImageFailed:'Falha ao obter o código de verificação da imagem',
  },
  technologySupport: {
    support: 'Suporte',
  },
  // 文档中心
  documentation: {
    searchPlaceholder: 'Entre a palavra-chave',
    search: 'Buscar',
    productFilter: 'Produto',
    typeFilter: 'Tipo',
    formatFilter: 'Formatar',
    publishDate: 'Organizar por data de lançamento',
    downloadTimes: 'Organizar por quantidade de downloads',
    hotDoc: 'Documentos quantes',
    result: 'Resultados',
    download: 'Download',
    batDownload: 'Batch Download',
    checkAll: 'Marcar todos',
    paginalNumber: 'Número da página',
  },
  // 视频中心
  videoLibrary: {
    title: 'Vídeos',
    searchVideo: 'Buscar Vídeo',
    all: 'Todos',
    branding: 'Marca',
    products: 'Produtos',
    solutions: 'Soluções',
    technicalSupport: 'Suporte Técnico',
    training: 'Treinamento'
  },
  faq: {
    faq: 'FAQ',
    Products: 'Produtos',
    Solutions: 'Soluções',
    Partners: 'Parceiros',
    Marketing: 'Marketing',
    Others: 'Outros',
    placeholder: 'deixe em branco e você receberá todas as FAQs',
    help: 'Como usar a pesquisa de perguntas frequentes'
  },
  contactUs: {
    contactUsPricingInfo:'Contato para informação de preço',
    contactUs: 'Fale conosco',
    options: {
      pricingInfo: 'Preço/Informação',
      serviceRequests: 'Requerimentos de serviços',
    },
    pricingInfo: {
      Title: 'Fale conosco',
      Desc1: 'por favor, preencha os campos abaixo.',
      Desc2: 'Nós iremos retornar em breve.',
      Mandatory: 'Mandatório',
      FirstName: 'Primeiro Nome',
      LastName: 'Último Nome',
      Email: 'Email',
      Telephone: 'Telefone',
      CountryRegion: 'Estado/Cidade',
      Partner: 'Parceiros',
      Company: 'Empresa',
      Industry: 'Indústria',
      JobTitle: 'Cargo',
      FavoriteProducts: 'Produtos Favoritos',
      Budget: 'Orçamento',
      BrieflyPlaceholder: 'Descreva brevemente os requisitos do seu projeto. ',
      ConfirmText: 'Concordo em receber informações sobre produtos, soluções, serviços e ofertas da HOLOWITS e parceiros autorizados da HOLOWITS. Compreendo que posso cancelar a subscrição a qualquer momento.',
      Yes: 'Sim',
      No: 'Não',
      AgreeText: 'Eu entendo e concordo com o uso e transferência de minhas informações pessoais pela HOLOWITS de acordo com a HOLOWITS ',
      PrivacyPolicy: 'Política de Privacidade',
      TermsOfUse: 'Termos de Uso',
      And: 'E',
      placeholder: 'Por favor insira',
      submit: 'Enviar',
      fieldRequired: 'Esse campo é requerido.',
      emailValidator: {
        'at-error': 'Um e-mail deve conter um &quot;@&quot; .',
        'account-letter-forbidden': 'O endereço de e-mail (por exemplo: Adam.Smith@abc.com) contém caracteres inválidos.',
        'prevDot-error': 'O nome de e-mail (por exemplo: Adam.Smith@abc.com) não deve começar ou terminar com um ponto (“.”).',
        'double-dot-error:': 'Dois pontos ou duas vírgulas jutas sucessivas (“.”) é inválido',
        'domain-error': 'O domínio (por exemplo: Adam.Smith@abc.com) deve conter pelo enos um domínio.',
        'length-error': 'Um endereço de e-mail não deve possuir mais do que 254 caracteres.',
        'nextLetter-forbidden': 'O domínio (por exemplo: Adam.Smith@abc.com) contém caracteres inválidos.',
        'nextLine-error': 'O domínio (por exemplo: Adam.Smith@abc.com) não deve começar ou terminar com um hífen (“-“).',
        'account-length-error': 'Um nome de e-mail (por examplo: Adam.Smith@abc.com) não deve ter mais do que 63 caracteres.',
      },
    },
  },
};
