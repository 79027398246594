<template>
  <div class="download-list-box">
    <div class="download-list-btn" @click="hide = true">
        <i class="el-icon-folder"></i>
    </div>
    <div class="download-list" v-show="hide">
        <div class="down-operation-bar">
            <span>Downloading...</span>
            <div class="ope-hide-btn" @click="hide = false"><i class="el-icon-minus"></i></div>
        </div>
        <div class="list">
            <ul>
                <li class="item" v-for="item in fileNames" :key="item">
                    <DownloadItem :fileName="list[item].name"></DownloadItem>
                </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DownloadItem from './DownloadItem.vue';
const env = process.env.NODE_ENV
export default {
name: 'DownloadList',
components: {
    DownloadItem
},
props: {

},
data() {
    return {
        hide: false
    }
},
// this.$store.commit('downloadList/setFileNames', fileName);
// this.$store.commit('downloadList/setList', {[productId]: productName});
computed: {
    fileNames() {
        return this.$store.state.downloadList.fileNames
    },
    list() {
        return this.$store.state.downloadList.list
    }
},
watch: {
    "$store.state.downloadList.fileStatus": {
        deep: true,
        handler(n, o) {
            for (let item in n) {
                if (this.list[item].status === 'await') {
                    this.hide = true;
                    this.downloadFile(this.list[item].url, this.list[item].name)
                }
            }
        }
    }
},
methods: {
    async downloadFile(url, fileName) {
        let src1 = '';
        // if (env === 'development') {
            if (url.includes('https://resources.holowits.com.sg/')) {
                src1 = url.replace('https://resources.holowits.com.sg/', '/resource/');
            } else if (url.includes('https://resources.holowits.com/')) {
                src1 = url.replace('https://resources.holowits.com/', '/resource/');
            } else {
                src1 = '/resource/'+ url;
            } 
        // } else {
        //     if (!url.includes('holowits.com')) {
        //         src1 = `https://resources.holowits.com/${url}`
        //         console.log(src1)
        //     }
        // }
        
        this.$store.commit('downloadList/setListProperty', {
            key: fileName,
            property: 'status',
            value: 'downloading',
        });
        // console.log(src1);
        // return;
        let process = 0;
        let cancel = null;
        const {CancelToken, isCancel} = axios;
        try {
            const res = await axios({
                method: 'get',
                url: src1,
                responseType: 'blob', 
                onDownloadProgress: (evt) => {
                    process = (evt.loaded / evt.total * 100 | 0);
                    // console.log(fileName, process);
                    this.$store.commit('downloadList/setListProperty', {
                        key: fileName,
                        property: 'status',
                        value: process == 100 ? 'success' : 'downloading',
                    })
                    this.$store.commit('downloadList/setListProperty', {
                        key: fileName,
                        property: 'process',
                        value: process,
                    })
                    this.$store.commit('downloadList/setListProperty', {
                        key: fileName,
                        property: 'cancel',
                        value: cancel,
                    });
                },
                cancelToken: new CancelToken((c) => {
                    cancel = c;
                })
            })
            let blob = new Blob([res.data]);
            const url1 = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.setAttribute('target', '_blank');
            a.setAttribute('download', `${fileName}`);
            a.href = url1;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url1);

        } catch(e) {
        // console.log(e)
            if (isCancel(e)) {
                this.$store.commit('downloadList/setListProperty', {
                    key: fileName,
                    property: 'status',
                    value: 'stop',
                });
                this.$store.commit('downloadList/setStatusProperty', {
                    key: fileName,
                    property: 'status',
                    value: false,
                });
                this.$message.warning("Downloading has stopped");
            } else {
                this.$store.commit('downloadList/setList', {
                        [fileName]: {
                            name: fileName,
                            status: 'error',
                            url: url,
                            cancel: cancel,
                            process: process
                        }
                });
                this.$store.commit('downloadList/setStatus', {
                    key: fileName,
                    property: 'status',
                    value: false,
                });
                this.$message.error("Download failed");
            }
        } finally {
            
        }
    },
}
}
</script>

<style lang="less" scoped>
.download-list-box {
    .download-list-btn {
        position: fixed;
        background-color: #FFFFFF;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        color: #c7000b;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        box-shadow: 0 0 6px #0000001f;
        cursor: pointer;
        z-index: 5;
        right: 20px;
        bottom: 40px;
        &:hover {
            background-color: #F2F6FC;
        }
    }
    .download-list {
        position: fixed;
        background-color: #FFFFFF;
        width: 430px;
        height: 300px;
        border-radius: 6px;
        z-index: 5;
        right: 160px;
        bottom: 40px;
        // right: 20px;
        // bottom: 160px;
        font-size: 20px;
        box-shadow: 0 0 9px 0px #bfbbbb69;
        overflow: hidden;
        .down-operation-bar {
            font-size: 14px;
            text-align: center;
            background-color: #f9e6e7;
            padding: 5px 0;
            color: #c7000b;
            position: relative;
            .ope-hide-btn {
                color: #c7000b;
                position: absolute;
                top: 0px;
                right: 10px;
                font-size: 20px;
                font-weight: bold;
                cursor: pointer;
            }
        }
        .list {
            padding: 10px;
            // padding-right: 20px;
            height: calc(100% - 30px);
            overflow-y: auto;
            // background-color: #f6f7f8;
            .item {
                // border-top: 1px solid #bfbbbb69; 
                // border-bottom: 1px solid #bfbbbb69;
                &:first-child{
                    border-top: 1px solid #F2F2F2;
                    border-bottom: 1px solid #F2F2F2;
                }
                &:not(:first-child) {
                    border-bottom: 1px solid #F2F2F2;
                }
                .down-entity {

                }
            }
        }
    }
}
</style>