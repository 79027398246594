export default {
    common: {
      querySpecifications: 'Consultar especificaciones técnicas',
      login: 'Iniciar sesión',
      logout: 'Salir de sesión',
      Home: 'Menú principal',
      ProductCenter: 'Centro de productos',
      WhatIsaSoftwareDefinedCamera: '¿Qué es una cámara definida por software',
      ProductDetail: 'Detalle del producto',
      Solutions: 'Soluciones',
      SolutionsDetail: 'Detalle de soluciones',
      Products: 'Productos',
      Tools: 'Herramientas',
      Support: 'Soporte',
      Documentation: 'Documentación',
      news: 'Centro de noticias',
      newsDetail: 'Detalle de noticias',
      forPartners: 'Para Socios',
      activity:'Actividad',
      SoftwareDefinedCamera: 'Cámara definida por software',
      IntelligentVisionSystem: 'IVS',
      Accessory: 'Accesorios',
      ContactUs: 'Contáctenos',
      NewsRoom: 'Sala de noticias',
      NewsRoomDetail: 'Detalle de la sala de noticias',
      Partner: 'Socio',
      Resources: 'Recursos',
      ResourceCenter: 'Centro de recursos',
      VideoLibrary: 'Videoteca',
      AftersalesSupport: 'Soporte postventa',
      Aftersales: 'Preguntas y respuestas postventa',
      Privacy: 'Política de Privacidad',
      TermsOfUse: 'Términos de uso',
      clear:"Limpiar",
      RefineBy: 'Filtrar por',
      placeholder: 'Introduzca el modelo, el nombre o la palabra clave',
      Favorites: 'Favoritos',
      Compare: 'Comparar',
      More: 'Más',
      all: 'Todo',
      enter: 'Introduzca el modelo, el nombre o la palabra clave',
      collapse: 'Contraer',
      expand: 'Ampliar',
      confirm:"Confirmar",
      cancel:"Cancelar",
      channel:"Canal",
    },
    login: {
      title: 'Iniciar sesión con ID de usuario',
      email: 'Correo Electrónico',
      pwd: 'Contraseña',
      changePwd: 'Cambiar contraseña',
      forgetPwd: 'Olvidé contraseña',
      register: 'Registro',
      rulesEmail: 'El correo electrónico no es válido',
    },
    //导航栏
    navbar: {
      aboutTitle: 'ACERCA DE',
      about: 'ACERCA DE HOLOWITS',
      products: 'PRODUCTOS',
      productCenter: 'CENTRO DE PRODUCTOS',
      technicalSupport: 'SOPORTE TÉCNICO',
      FAQ:'Preguntas Frecuentes',
      solution: 'SOLUCIÓN',
      documentation: 'DOCUMENTACIÓN',
      support: 'SOPORTE',
      certificate: 'Certificado',
      pocDemo: 'Demostración de PoC',
      news: 'SALA DE NOTICIAS',
      events:'EVENTOS',
      tools: 'HERRAMIENTAS',
      partners: 'SOCIOS',
      resources: 'RECURSOS',
      forPartners: 'PARA SOCIOS',
      forCustomers: 'PARA CLIENTES',
      channel:"DÓNDE COMPRAR",
      videoLibrary: "VÍDEO",
      contact: "TIENDA DE ALGORITMOS",
      algorithmstore: "TIENDA DE ALGORITMOS",
      terms: "POLÍTICAS DE GARANTÍA",
      warrantypolicy: "WARRANTYPOLICY",
      firmware: "FIRMWARE",
      software: "SOFTWARE",
      becomeAPartner: 'Conviértase en un socio',
      dealRegistration: 'REGISTRO DE OPORTUNIDAD'
    },
    home: {
      'whatIsNew': "¿Qué hay de nuevo?",
      'ProductSpotlight': 'Productos destacados',
      'Events&News': 'Eventos y Noticias',
      'learnMore': 'Más información'
    },
    //合作伙伴
    partners:{
      banner:{
        title:'Certificado por HOLOWITS',
        summery:'Especialista en Visión Inteligente',
      },
      introduction:{
        title:'Introducción a las clases en línea',
        desc:'Este curso presenta una serie completa de cámaras definidas por software (SDC) HOLOWITS, grabadores de vídeo en red (NVR), dispositivos inteligentes Micro Edge y soluciones y le proporciona diversos materiales de aprendizaje y exámenes que cubren los conocimientos de preventa y postventa. Usted puede obtener la certificación HOLOWITS de Especialista Certificado en Visión Inteligente  después de aprobar los exámenes.',
      },
      course:{
        title:'Valor del curso',
        value:'Este curso le ayudará a aprender sobre los aspectos más destacados de los productos y soluciones de HOLOWITS, dominar las habilidades de preventa y proporcionar a los clientes servicios profesionales de postventa.',
      },
      certification:{
        title:'Valor de la certificación',
        value:'Este certificado puede brindarle ventajas competitivas en el mercado, mostrar su experiencia en productos, soluciones y servicios postventa de HOLOWITS, y permitir que los clientes y empleadores identifiquen fácilmente sus capacidades profesionales.',
      },
      contents:[
        {text: 'HOLOWITS SDC Diapositivas técnicas principales'},
        {text: 'HOLOWITS Diapositivas técnicas principales de Micro Edge Inteligente'},
        {text: 'HOLOWITS HWT-NVR800 Guías técnicas principales'},
        {text: 'HOLOWITS Guía de configuración de accesorios'},
        {text: 'HOLOWITS Solución Inteligente para Minoristas'},
        {text: 'HOLOWITS Solución de Campus Inteligente'},
        {text: 'HOLOWITS Solución de red eléctrica inteligente'},
        {text: 'HOLOWITS Solución de transporte inteligente'},
        {text: 'Guía rápida para la inspección en sitio y puesta en marcha de la cámara de captura de objetivos'},
        {text: 'Guía rápida para la inspección en sitio y puesta en marcha de la cámara de ePolice'},
        {text: 'HOLOWITS HWT-NVR800 Instalación y Puesta en Marcha'},
        {text: 'HOLOWITS HWT-IVS1800 Instalación y Puesta en Marcha'},
        {text: 'Cámara de la serie A de HOLOWITS e Introducción de XVR'},
        {text: 'Manual de operación de la aplicación HOLOWITS'},
        {text: 'Introducción a la cámara de la serie E de HOLOWITS'},
      ],
      learningGuide:{
        title:'Guía de aprendizaje',
        desc:'Para aprobar los exámenes, debe estudiar meticulosamente materiales y videos relacionados. Después de obtener la certificación, debe actualizar el certificado cada dos años.',
        text:'Es necesario registrarse para el primer inicio de sesión',
        registerCode:'Código de registro:',
        registerValue:'UJIQ-RVH-PQW',
        btnValue:'Empezar a aprender',
      },
    },
    //关于Holowits
    aboutHWT:{
      title:'Acerca de HOLOWITS',
      desc:'HOLOWITS es un proveedor global de productos y soluciones inteligentes de Internet de las Cosas (AIoT) basadas en tecnologías de sensibilización multidimensional, e integra una amplia gama de capacidades como I+D, producción, distribución, ventas y capacidades de servicio. HOLOWITS cuenta con tecnologías de detección inteligentes, una cadena industrial completa y potentes capacidades de soporte técnico. En respuesta a la creciente demanda global de inteligencia, HOLOWITS esta comprometido a desarrollar cámaras de inteligencia artificial (AIC), plataforma inteligente de vídeo (VIP), así como servicios en la nube. Estas ofertas se pueden aplicar en una amplia variedad de escenarios tales como ciudad inteligente, transporte inteligente, energía inteligente, manufactura inteligente, campus inteligente y venta minorista inteligente. Nuestro objetivo es trabajar juntos para construir un mundo inteligente en donde todas las cosas detecten. Para lograr esto, nos dedicamos a proporcionar productos y soluciones seguros, confiables, fáciles de usar y sobresalientes para el mercado global.',
    },
    // 产品中心
    products: {
      productTypes: 'Tipo de producto',
      sort: 'Ordenar',
      overAll: 'En general',
      hot: 'Mas popular',
      latest: 'Novedades',
      listPrice: 'Precio de lista',
      preSales: 'Pre-venta',
      model: 'Modelo',
      processor: 'Procesador',
      memory: 'Memoria',
      computingPower: 'Procesamiento',
      series: '',
      form: ''
    },
    // 产品详情
    productDetail: {
      productDetail: 'Detalles',
      accessoryDetail: 'Detalles de accesorios',
      specifications: 'Características',
      certification: 'Certificación',
      introduction: 'Introducción',
      download: 'Descargar',
      documentation: 'Documentación',
      huaweiCcsc: 'imgs/product_detail/mapen.png',
      clickHere: 'Haga clic aquí',
      dataSheet: 'Hoja de datos',
      afterSalesDocuments: 'Documentos de posventa',
      copy: 'Copiar',
      reportError: 'Reportar error',
      description: 'Descripcion',
      describeError: 'Por favor describa el error que encontró.',
      picture: 'Fotografía',
      onlyJpg: 'Solo se admite el formato.jpg.',
      attachment: 'Archivos adjuntos',
      selectFile: 'Seleccionar archivo',
      anonymous: 'Anónimo',
      submit: 'Envíar',
      accessory: 'Accesorio',
      technicalSpec: 'Especificaciones técnicas',
      dimensions: 'Dimensiones',
      remarks: 'Comentarios',
      optionalFitting: 'Accesorio opcional',
      favorites: 'Favorito',
      keySpec: 'Especificaciones clave',
      viewAll: 'Iniciar sesión para ver todo',
      clicktoLoad: 'Haga clic aquí para descargar',
      completeSpecificationList: 'Lista de especificaciones completa',
    },
    // 技术支持
    support: {
      wahtDoYouNeedHelpWith: '¿En que necesita ayuda?',
      title: 'Bienvenido al Soporte Técnico de HOLOWITS',
      explain: 'El Soporte Técnico de HOLOWITS proporciona soporte de primera clase para sus productos y soluciones de HOLOWITS. Nuestros servicios de soporte en línea e ingenieros de todo el mundo están listos para ayudarle en cualquier momento y en cualquier lugar.',
      documentation: 'Documentación',
      softwareDownload: 'Descarga de software',
      video: 'Vídeo',
      searchPlaceholder: 'Introducir palabra clave',
    },
    contact: {
      theme: '¿Necesita soporte técnico?',
      content: 'Le aconsejamos que se ponga en contacto con el distribuidor nacional responsable de HOLOWITS en su país o región para obtener soporte técnico.',
      tip1: 'Para contactar a los distribuidores y canales de venta de HOLOWITS',
      tip2: 'Para contactar con HOLOWITS, envíe su correo electrónico a',
      button1: 'Haga clic aquí',
      button2: 'support@holowits.com',
      title: '',
      explain: ''
    },
      // 渠道中心
    channel: {
      partnerName: 'Nombre de socio',
      countryOrRegion: 'País/Región',
      partnerType: 'Tipo de socio',
      searchPlaceholder:"Buscar en Lista de Socios",
      website: 'Sitio Web',
      email: 'Correo Electrónico',
      phone: 'Teléfono',
      address: 'Dirección de correo electrónico'
    },
    // 新闻中心
    news: {
      searchPlaceholder: 'Introducir palabra clave',
      search: 'Buscar',
    },
    // 安防工具
    securityTools: {
      cameradetectionDistance: 'Calcular la distancia de detección de la cámara',
      disable: 'Desactivar',
      enable: 'Activar',
      videoResolution: 'Resolución de vídeo',
      encodingBitRate: 'Velocidad de bits de codificación',
      NumberOfNVRs: 'Número de NVRs',
      NumberOfSDCs: 'Número de SDCs',
      microCheckpoint: 'Micro punto de control',
      ePoliceCheckpoint: 'Punto de control/ePolicía',
      targetDetection: 'Detección/reconocimiento de objetivos',
      calculateBandwidth: 'Calcular ancho de banda',
      calculateRc: 'Calcular la capacidad de grabación',
      NVRAccess: 'Acceso a NVR',
      ipTools: 'Herramienta de diseño de sistema de video IP',
      //提示信息
      microResolutionTips: 'En el modo de micropunto de control, se recomienda que la resolución de la matrícula de la placa sea de al menos 80 píxeles.',
      microInstallationTips: 'En los escenarios de carril, se recomienda que la altura de instalación sea de 4.5 m a 6 m. En las entradas y salidas del campus, se recomienda que la altura de instalación sea de 1.3 m a 1.5 m. Puede establecer la altura de instalación en función de los requisitos del sitio.',
      ePoliceResolutionTips: 'En el modo ePolice, la resolución de la matrícula debe ser mayor o igual a 100 píxeles. En el modo de punto de control, si solo es necesario capturar la matrícula de las placas, la resolución de la matrícula de la placa debe ser mayor o igual a 100 píxeles. Si los objetivos dentro de los vehículos también necesitan ser capturados, la resolución de la matrícula de la placa debe ser mayor o igual a 180 píxeles.',
      ePoliceInstallationTips: 'La altura de instalación recomendada oscila entre 5.5 m y 6.5 m.',
      targetResolutionTips: 'Detección de objetivos: Para cámaras de enfoque corto (menos de 12 mm), la resolución recomendada del objetivo es de al menos 80 píxeles. Para cámaras de enfoque medio y largo (12 mm o más), la resolución recomendada es de al menos 120 píxeles. Reconocimiento de objetivos: Para cámaras de enfoque corto (menos de 12 mm), la resolución recomendada para reconocimiento de objetivos es de al menos 110 píxeles. Para cámaras de enfoque medio y largo (12 mm o más), la resolución recomendada es de al menos 120 píxeles.',
      targetInstallationTips: 'Detección de objetivos: Para cámaras con una distancia focal fija de 2.8 mm o 3.6 mm, la altura de instalación recomendada oscila entre 2 m y 2.5 m. Para cámaras con una distancia focal fija de 6 mm, la altura de instalación recomendada oscila entre 2 m y 3 m. Para cámaras con otras distancias focales, la altura de instalación recomendada oscila entre 2.5 m y 3.5 m. Reconocimiento de objetivos: Para las cámaras de enfoque corto, la altura de instalación recomendada oscila entre 2.5 m y 3 m. Para las cámaras de enfoque largo, la altura de instalación recomendada oscila entre 2.5 m y 3.5 m.',
    },
  
    // 提示信息
    tipInfo: {
      exportFailure: 'Error de exportación.',
      addProToComp: 'Añade el producto a comparar.',
      selectLeastTwo: 'Seleccione al menos dos productos para la comparación.',
      selectMaximum: 'Puede seleccionar un máximo de cuatro productos para la comparación.',
      copySuccess: 'Copia exitosa.',
      noData: 'No hay datos.',
      selectOther: 'El dispositivo seleccionado no contiene ningún dato. Por favor seleccione otro dispositivo.',
      onlyJpg: 'Solo se admiten imágenes JPG.',
      exeNoUpload: 'No se pueden cargar archivos EXE.',
      problemNotEmpty: 'La descripción del problema no puede estar vacía.',
      submitSuccess: 'Se ha enviado correctamente.',
      submitFailed: 'Error de envío. Inténtalo de nuevo más tarde.',
      loginSuccess: 'Inicio de sesión exitosa.',
      errorPwd:'Las dos contraseñas son inconsistentes',
      confirmLogout:'Confirma para salir de la cuenta actual?',
      tooManyRequest:'La obtención del código de autenticación es demasiado frecuente. Por favor, inténtalo de nuevo en 3 minutos',
    },
    technologySupport: {
      support: 'Soporte técnico',
    },
    // 文档中心
    documentation: {
      searchPlaceholder: 'Introduce la palabra clave',
      search: 'Buscar',
      productFilter: 'Producto',
      typeFilter: 'Escenarios',
      languageFilter: 'Idioma',
      formatFilter: 'Formato',
      publishDate: 'Ordenar por fecha de lanzamiento',
      downloadTimes: 'Ordenar por número de descargas',
      hotDoc: 'Documentos mas populares',
      result: 'Resultado',
      download: 'Descargar',
      batDownload: 'Descargar por lotes',
      checkAll: 'Comprobar todo',
      paginalNumber: 'Número de página',
    },
    // 视频中心
    videoLibrary: {
      title: 'Videoteca',
      searchVideo: 'Buscar vídeo',
      all: 'Todo',
      branding: 'Marca',
      products: 'Productos',
      solutions: 'Soluciones',
      technicalSupport: 'Soporte técnico',
      training: 'Entrenamiento',
      hOLOWITSClub: 'Club HOLOWITS',
      algorithm: 'Algoritmo',
      technologies: 'Tecnologías'
    },
    faq: {
      faq: 'Preguntas Frecuentes',
      Products: 'Productos',
      Solutions: 'Soluciones',
      Partners: 'Socios',
      Marketing: 'Mercadotecnia',
      Others: 'Otros',
      placeholder: 'Deje en blanco y obtenga todas las preguntas frecuentes',
      help: 'Cómo usar la búsqueda de preguntas frecuentes'
    },
    learnRegister: {
      pageTitle: "Registro para el examen de Especialista Certificado HOLOWITS",
      pageDesc1: "Por favor llene el siguiente formulario.",
      pageDesc2: "Después de la verificación, le enviaremos un correo electrónico.",
      hint1: "Por favor, pruebe que es un empleado de su empresa (Puede cargar su tarjeta de personal o tarjeta de presentación)",
      hint2: "Por favor, cargue la licencia de negocio de su empresa",
      required: "Este campo es obligatorio.",
      upload: "Cargar",
      successPanelTitle: "Se ha enviado correctamente",
      successPanelText: "La solicitud ha sido enviada, el administrador se pondrá en contacto con usted por correo electrónico.\n Si su empresa desea convertirse en nuestro socio certificado Tier 2, por favor haga clic aquí para registrarse. Después de que un empleado de su empresa pase el examen de especialista certificado HOLOWITS, su empresa puede convertirse en nuestro socio certificado Tier 2 y también podrá mostrarse en nuestro sitio web.",
      inputPlaceholder: "Por favor describa brevemente por qué asiste a nuestro examen de especialista HOLOWITS"
    },
    register: {
      register: "Registro",
      email: "Correo Electrónico",
      companyName: "Nombre completo de la empresa",
      name: "Nombre de usuario",
      country: "País/Región",
      description: "Por favor, describa la relación entre usted o su empresa con HOLOWITS",
      code: "Código de verificación",
      cancel: "Cancelar",
      submit: "Envíar",
      successPanelTitle: "Se ha enviado correctamente",
      successPanelText: "La solicitud ha sido enviada, nos pondremos en contacto con usted por correo electrónico.",
      back: "Atrás",
      rules: {
        email: "El correo electrónico no puede estar vacío.",
        companyName: "El nombre de la compañía no puede estar vacío.",
        name: "El nombre no puede estar vacío.",
        country: "País/Región no puede estar vacío.",
        description: "La descripción no puede estar vacía.",
        code: "El código no puede estar vacío."
      }
    },
    bind: {
      successPanelText: "La solicitud ha sido enviada, nos pondremos en contacto con usted por correo electrónico.",
    },
    reseller: {
      pageTitle: "Conviértase en un socio",
      companyName: "Nombre de la empresa",
      webSite: "Sitio web oficial",
      region: "Región",
      coopCountry: "País/Región",
      preSales: "Correo electrónico para soporte de Pre-venta",
      postSales: "Correo electrónico para soporte Post-venta",
      marketing: "Correo electrónico para soporte de marketing",
      email: "Correo electrónico para contactarlo",
      companyEmail: "Correo electrónico para ser registrado como su cuenta de empresa",
      phone: "Número de teléfono para soporte de Pre-venta",
      address: "Dirección de la empresa",
      partnerType: "Tipo de socio",
      uploadLog: "Cargar un gráfico en alta resolución del logotipo de tu empresa (jpg. o png.)",
      upload: "Cargar",
      downlaod: "Descargar plantilla de acuerdo",
      businessLicense: "Subir la licencia de negocio de su empresa (Archivo en más de 50M) (Se admite formato tipo pdf. png. jpg. jpeg. webp)",
      agreeFieldText: "Autorizo a HOLOWITS a incluir mi empresa en los sitios web de HOLOWITS.",
      successPanelText: "La solicitud ha sido enviada, el administrador se pondrá en contacto con usted por correo electrónico.",
      distributor: "Distribuidor",
      uploadTips: "Descargue e imprima el archivo, complete la información relevante y firme a mano, luego cargue la copia escaneada del archivo.",
    },
    contactUs: {
      contactUsPricingInfo:'Contáctenos para información de precios',
      contactUs: 'Contáctenos',
      options: {
        pricingInfo: 'Precios/Información',
        serviceRequests: 'Solicitudes de servicio',
      },
      pricingInfo: {
        Title: 'Contáctenos',
        Desc1: 'Por favor, llene el siguiente formulario.',
        Desc2: 'Nos pondremos en contacto con usted pronto.',
        Mandatory: 'Obligatorio',
        FirstName: 'Nombre de usuario',
        LastName: 'Apellido',
        Email: 'Correo Electrónico',
        Telephone: 'Teléfono',
        CountryRegion: 'País/Región',
        Partner: 'Socio',
        Company: 'Compañía',
        Industry: 'Industria',
        JobTitle: 'Posición',
        FavoriteProducts: 'Productos favoritos',
        Budget: 'Presupuesto',
        BrieflyPlaceholder: 'Describa brevemente los requisitos de su proyecto.',
        ConfirmText: 'Acepto recibir información sobre productos, soluciones, servicios y ofertas de HOLOWITS y socios autorizados de HOLOWITS. Entiendo que puedo cancelar mi suscripción en cualquier momento.',
        Yes: 'Sí',
        No: 'No',
        AgreeText: 'Entiendo y acepto el uso y transferencia de mi información personal por parte de HOLOWITS de acuerdo con HOLOWITS',
        PrivacyPolicy: 'Política de Privacidad',
        publictiy: 'Después de que un empleado de su empresa pase el examen de especialista certificado HOLOWITS, su empresa puede convertirse en nuestro socio certificado Tier 2 y podrá mostrarse en nuestro sitio web.',
        TermsOfUse: 'Términos de uso',
        And: 'y',
        placeholder: 'Por favor ingrese',
        submit: 'Envíar',
        fieldRequired: 'Este campo es obligatorio.',
        emailValidator: {
          'at-error': 'Una dirección de correo electrónico debe contener el símbolo @.',
          'account-letter-forbidden': 'El nombre de correo electrónico (ejemplo: Adam.Smith@abc.com) contiene caracteres no válidos.',
          'prevDot-error': "El nombre de correo electrónico (ejemplo: Adam.Smith@abc.com) no debe comenzar o terminar con un punto o punto ('.').",
          'double-dot-error:': "Dos puntos o períodos sucesivos ('.') juntos no son válidos.",
          'domain-error': 'El dominio (ejemplo: Adam.Smith@abc.com) debe contener al menos un nombre de dominio.',
          'length-error': 'An email address must not exceed 254 characters.',
          'nextLetter-forbidden': 'The domain (example: Adam.Smith@abc.com) contains invalid characters.',
          'nextLine-error': 'The domain (example: Adam.Smith@abc.com) must not start or end with a hyphen (“-“).',
          'account-length-error': 'Una dirección de correo electrónico no debe exceder los 254 caracteres.',
        },
        phoneFormatError: 'Ejemplo: Código de área (3-6 DIGITOS, por ejemplo 0066)Número de teléfono'
      },
    },
    algorithm: {
      label: "TIENDA DE ALGORITMOS",
      pageTitle: "Tienda de algoritmos HOLOWITS",
      navOptionTag: "Algoritmo inteligente",
      recommendation: "Recomendación de productos relacionados",
      selectorItemTitle: {
       "Product Application Scenario": "Escenario de la aplicación del producto",
       "Algorithm Classification": "Clasificación del algoritmo",
       "Applicable Platform": "Plataforma aplicable",
       "Algorithm type": "Tipo de algoritmo"
      },
      tag: {
       "All": "All",
       "Transportation": "Transporte",
       "Government": "Gobierno",
       "Finance": "Financiero",
       "Energy": "Energía",
       "Public Security": "Seguridad Pública",
       "Personnel Detection": "Detección de Personal",
       "Intelligent Transportation": "Transporte Inteligente",
       "Text Detection": "Detección de texto",
       "Behavior Analysis": "Análisis de comportamiento",
       "Voice Detection": "Detección de voz",
       "Video Analysis": "Análisis de vídeo",
       "Perimeter Events": "Eventos Perimetrales",
       "Event Detection": "Detección de eventos",
       "Object Detection": "Detección de objetos",
       "Detection Analysis": "Análisis de detección",
       "SDC": "SDC",
       "HWT-IVS1800": "HWT-IVS1800",
       "HOLOWITS": "HOLOWITS",
       "HOLOWITS SELECTED": "HOLOWITS SELECCIONADO",
       "OTHER": "OTROS"
      },
      label: {
       "Subway": "Metro",
       "Highway": "Carretera",
       "Railway": "Ferrocarril",
       "Airport": "Aeropuerto",
       "Public Transport": "Transporte público",
       "Digital Government": "Gobierno Digital",
       "Bank": "Banco",
       "Insurance": "Seguros",
       "Wind Power": "Energía eólica",
       "Solar Energy": "Energía solar",
       "Electric Power": "Energía Eléctrica",
       "Gas": "Gas",
       "Water Conservancy": "Conservación del agua",
       "Petroleum": "Petróleo",
       "Forest": "Bosque",
       "City Management": "Gestión de la ciudad",
       "Fire Detection": "Detección de incendios",
       "Manufacturing": "Manufactura",
       "Agriculture": "Agricultura",
       "Retail": "Venta minorista",
       "Logistics": "Logística",
       "Service": "Servicio",
       "Medical Treatment": "Tratamiento médico",
       "Catering Industry": "Industria restaurantera",
       "Tourism": "Turismo",
       "Education": "Educación",
       "Campus": "Campus",
       "Family": "Familia",
       "Internet of Things(IoT)": "Internet de las cosas (IoT)",
       "Target Recognition": "Reconocimiento de objetivos",
       "Personnel Attribute Analysis": "Análisis de atributos de personal",
       "Overall Target Detection": "Detección de objetivos generales",
       "License Plate detection": "Detección de matrícula de placa",
       "Vehicle Attribute Detection": "Detección de atributos del vehículo",
       "Non-standard Vehicle Inspection": "Inspección de vehículos no estándar",
       "Illegal Detection": "Detección ilegal",
       "Vehicle Detection": "Detección de vehículos",
       "Omni-data Structuring": "Estructuración de Omni-datos",
       "OCR": "OCR",
       "Regulation Breach Detection": "Detección de incumplimiento de regulación",
       "Crowd Analysis": "Análisis de multitudes",
       "Voice Synthesis": "Síntesis de voz",
       "Voice Identification": "Identificación por voz",
       "Video Abstract": "Resumen de vídeo",
       "Perimeter Intrusion": "Intrusión perimetral",
       "Water Pollution Detection": "Detección de contaminación del agua",
       "Carrying Dangerous Objects": "Llevar objetos peligrosos",
       "Carrying Prohibited Items": "Llevar artículos prohibidos",
       "Construction Machinery Detection": "Detección de maquinaria de construcción",
       "Foreign Matter Detection": "Detección de material extraño",
       "Wearing Detection": "Detección de desgaste",
       "Equipment Status Detection": "Detección del estado del equipo",
       "Instrumentation Detection": "Detección de instrumentación",
       "Defect Detection": "Detección de defectos",
       "Remote Sensing": "Detección remota",
       "Robot": "Robot",
       "X Series": "Serie X",
       "M Series": "Serie M",
       "C Series": "Serie C",
       "D Series": "Serie D",
       "HWT-IVS1800": "HWT-IVS1800",
       "Incidents Detection": "Detección de Incidentes",
       "HOLOWITS": "HOLOWITS",
       "HOLOWITS SELECTED": "HOLOWITS SELECCIONADO",
       "OTHER": "OTROS",
       "Vehicle Detection": "Detección de vehículos",
       "Video Synopsis": "Sinópsis de vídeo",
      },
      details: {
        "update tips": "No disponible, espere la actualización.",
        "Service Provider": "Proveedor de servicios",
        "To contact service provider": "Para ponerse en contacto con el proveedor de servicios",
        "Service Provider Profile": "Perfil del proveedor de servicios",
        "tips": "Las descripciones de la página son proporcionadas por los proveedores de servicios en función de los resultados de sus pruebas. Los efectos reales pueden variar dependiendo de los escenarios de aplicación. Para obtener más información sobre las capacidades detalladas del producto, póngase en contacto con el equipo de operaciones de la tienda.",
        "tags": {
          "Product Details": "Detalles del producto",
          "Associated Product": "Producto asociado",
          "Customer Case": "Caso de cliente",
          "Customer Evaluation": "Evaluación del cliente",
          "Support": "Soporte"
        },
        smallTags: {
          "Parameters": "Parámetros",
          "Introduction": "Introducción",
          "Version": "Versión",
          "Highlights": "Características destacadas",
          "Scenario Requirements": "Requerimientos de escenario",
          "Graphic presentation": "Presentación gráfica",
          "Solution with this algorithm": "Solución con este algoritmo",
          "Supporting Applications": "Aplicaciones de apoyo",
          "User Manual": "Manual de usuario"
        }
      }
     },
     terms: {
      title1: "Soporte técnico",
      title2: "Política de garantía de productos HOLOWITS",
      jumpPrompt: "Revisar la política de garantía",
    },
    specialist: {
      register: "Registro",
      startLearning: "Empezar a aprender"
    },
    csp: {
      title1: "Tipo de socio",
      title2: "Idioma", 
      countLabel: "Resultados encontrados",
      placeholder: "Introducir palabra clave",
    },
    tools: {
      cameras: "Cámaras",
      scenario: "Escenario",
      videoResolution: "Resolución de video",
      encodingBitRate: "Velocidad de bits de codificación (Mbit/s)",
      streamChannels: "Canales de transmisión",
      packetLossRate: "Tasa de pérdida de paquetes",
      videoBufferingChannels: "Canales de búfer de vídeo",
      singleImageSize: "Tamaño de imagen individual",
      imagesUploadedPerSecond: "Imágenes cargadas Por Segundo",
      reservedBandwidthCoefficient: "Coeficiente de ancho de banda reservado",
      uploadVia: "Cargar vía FTP/SFTP",
      disable: "Deshabilitar",
      enable: "Habilitar",
      calculate: "Calcular",
      reset: "Reiniciar",
      basis: "Base",
      basisVideo: "Resolución de vídeo",
      basisiRate: "Velocidad de bits de codificación",
      encodingFormat: "Formato de codificación",
      coefficient: "Coeficiente de fluctuación de tasa de bits",
      storagePeriod: "Período de almacenamiento (Días) ",
      result: "Resultado",
      forReferenceOnly: "Solo para referencia",
      bandwidthRequired: "Ancho de banda requerido",
      about: "Acerca de",
      viewFormula: "Ver fórmula",
      capacityRequired: "Capacidad requerida",
      calculationObject: "Objeto de cálculo",
      obj1: "Número de NVRs",
      obj2: "Número de SDCs",
      model: "Modelo de NVR",
      microCheckpoint: "Micropunto de control",
      ePolic: "ePolice/Punto de Control",
      recognition: "Detección de objetivo/reconocimiento",
      resolutionRequirement: "Requisito de resolución (px)",
      sensorSize: "Tamaño del sensor",
      resolution : "Resolución (w)",
      focal: "Longitud focal (mm)",
      installationHeight: "Altura de instalación (m)",
      numberOfRrequired: "Número de NVRs requeridos",
      resultLabel1: "Distancia de detección (d)",
      tilt: "Ángulo de inclinación (α)",
      diagram: "Diagrama",
      designTool: {
        title1: "Herramienta de diseño del sistema de vídeo IP",
        title2: "Se han añadido cámaras HOLOWITS a la base de datos",
        title3: "Con la herramienta de diseño del sistema de vídeo IP puede:",
        small1: "Aumentar la eficiencia de los sistemas de seguridad mientras se reducen los costos y se encuentran las mejores ubicaciones para las cámaras.",
        small2: "Calcular los requisitos precisos de la lente y los sensores de la cámara fácilmente para lograr la densidad de píxeles deseada (PPM/PPF).",
        small3: "Mostrar visualmente las zonas de identificación, reconocimiento, observación, detección y monitorización de cada cámara en un plano del sitio.",
        small4: "Importar planos de planta en dibujos JPEG, PNG, PDF, TIFF o DWG de AutoCAD (Pro) para iniciar sus diseños.",
        small5: "Base de datos de más de 10,000 modelos de cámara, con parámetros preconfigurados para un diseño fácil.",
        small6: "Estimar el ancho de banda de la red y las necesidades de almacenamiento para guardar vídeo.",
        small7: "Planificar trayectorias de cable y calcular longitudes de cable.",
        small8: "Exportar diseños para incluir en propuestas u órdenes de trabajo.",
        title4: "Haga clic en el enlace para descargar:",
        title5: "Para actualizar la base de datos de la cámara, haga clic en?/Comprobar nuevas versiones/Sincronizar después de la instalación."
      }
    },
    firm: {
      label: "Descarga de firmware",
    },
    soft: {
      label: "Descarga de software",
    },
    newsRoom: {
      label: "SALA DE NOTICIAS",
      describe: "Noticias y anuncios, Proyectos Exitosos y Más",
      learnMore: "Aprender más"
    },
    dr: {
      label: "REGISTRO DE OPORTUNIDAD",
      subject: "Lista de oportunidades",
      title: "Condición de búsqueda",
      state1: "Aprobado",
      state2: "Desaprobado",
      state3: "Para ser aprobado",
      state4: "Cerrado",
      state5: "No cerrado",
      state6: "Pendiente",
      column1: "Nombre del proyecto",
      column2: "Nombre de empresa (solicitante)",
      column3: "Fecha esperada de pedido",
      column4: "Estado de aprobación",
      column5: "Estado",
      column6: "Fecha de caducidad",
      column7: "Nombre del cliente final"
    },
    drDetails: {
      subject1: "Información sobre la oportunidad",
      title1_1: "Tipo de negocio de la oportunidad",
      title1_2: "Nombre del proyecto",
      title1_3: "Nombre de la empresa (solicitante)",
      title1_4: "Probabilidad de ganar (%)",
      title1_5: "Fecha esperada del pedido",
      title1_6: "Licitación pública",
      title1_7: "Registro de la oportunidad a partir de",
      title1_8: "Fecha de caducidad",
      title1_9: "Descripción de la oportunidad",
      title1_10: "Antecedentes de la oportunidad",
      title1_11: "Correo electrónico del gerente del canal",
      subject2: "Avance del Proyecto y Dificultades para ayuda",
      title2_1: "Operación",
      title2_2: "Avance del proyecto",
      title2_3: "Dificultades para ayuda",
      title2_4: "Fecha de creación",
      subject3: "Competidores",
      title3_1: "Operación",
      title3_2: "Nombre del competidor",
      title3_3: "Producto competitivo",
      subject4: "Productos",
      title4_1: "Operación",
      title4_2: "Tipo de producto",
      title4_3: "Serie",
      title4_4: "Modelo",
      title4_5: "Cantidad estimada",
      subject5: "Partes comerciales",
      title5_1: "Clientes finales",
      title5_2: "Tipo de producto",
      title5_3: "Nombre del cliente final",
      title5_4: "Nombre del cliente final(Nativo)",
      title5_5_1: "Nombre",
      title5_5_2: "País/Región",
      title5_6: "Apellido",
      title5_7: "Estado",
      title5_8: "Correo electrónico",
      title5_9: "Ciudad",
      title5_10: "Teléfono de oficina",
      title5_11: "Ciudad",
      title5_12: "Dirección",
      title5_13: "Sitio web",
      title5_14: "Código Postal",
      title5_15: "Número de Teléfono.",
      title5_16: "Industria",
      title5_17: "Subindustria",
      title5_18: "Distribuidor",
      title5_19: "Nombre de la empresa",
      title5_20: "Dirección",
      title5_21: "Nombre de ubicación",
      title5_22: "Código Postal",
      title5_23: "Ciudad",
      title5_24: "Socio de Nivel 2 (si lo tiene) ",
      title5_25: "Nombre de empresa",
      title5_26: "Dirección",
      title5_27: "Nombre de la ubicación",
      title5_28: "Código Postal",
      title5_29: "Ciudad",
      subject6: "Equipo de ventas de socio",
      title6_1: "Operación",
      title6_2: "PRINCIPAL",
      title6_3: "Socio",
      title6_4: "Nombre de la empresa",
      title6_5: "Puesto",
      title6_6: "Nombre",
      title6_7: "Correo electrónico",
      title6_8: "Teléfono de oficina",
      tips1_1: "1) Con el fin de proporcionarle servicios relacionados por parte de HOLOWITS, HOLOWITS recopilará sus datos personales (incluyendo nombre, correo electrónico, etc.), los datos personales se almacenarán en los servidores de HOLOWITS TECHNOLOGIES PTE. LTD. (HOLOWITS) y compañías afiliadas. Esta información puede ser procesada por HOLOWITS y sus compañías afiliadas. Puede ser transferida al país/región de las compañías afiliadas o a ubicaciones aparte de aquellas en las que HOLOWITS recopila la información, y se accederá, almacenará y procesará en las ubicaciones mencionadas, Para obtener más información sobre cómo HOLOWITS procesa los datos, consulte la declaración de privacidad. Declaración de privacidad.",
      tips1_2: "2) Si los datos que introduce contienen información personal de un tercero, le rogamos que informe al tercero de lo anterior y obtenga su consentimiento o autorización, también. ",
      link1: "Declaración de privacidad.",
      tips2_1: "Solicitud para cerrar la oportunidad",
      tips2_2: "(Si selecciona esta opción, no podrá modificar o actualizar el contenido de esta oportunidad después de que se cierre).",
      submit: "Enviar",
    }
  };
  