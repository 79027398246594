<template>
  <header class="navbar">
    <div class="t-container">

      <div class="top-nav">
        <div class="t-container cont">
          <div class="avatar">
            <div>
              <el-dropdown>
                <span class="el-dropdown-link">
                    {{languageMap[$i18n.locale]}}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><span @click="setLanguage('en')">English</span></el-dropdown-item>
                  <el-dropdown-item><span @click="setLanguage('br')">Português (Brasil)</span></el-dropdown-item>
                  <el-dropdown-item><span @click="setLanguage('Arabic')">اللغة العربية</span></el-dropdown-item>
                  <el-dropdown-item><span @click="setLanguage('tur')">Türkçe</span></el-dropdown-item>
                  <el-dropdown-item><span @click="setLanguage('el')">Ελληνική</span></el-dropdown-item>
                  <el-dropdown-item><span @click="setLanguage('es')">Español</span></el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div>
              <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" v-if="loginFlag">
                <div class="avatar-wrapper">
                  <img src="@/assets/image/login/icon_login_white.png" class="t-yd-icon" alt="avatar">
                  <span class="user-name">{{ currentAccount | userAvatarFilter }}</span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <span style="display:block;" @click="goPresonalCenter">My HOLOWITS</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span style="display:block;" @click="logout">{{ $i18n.t('common.logout') }}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <div v-else class="avatar-wrapper">
                <img src="@/assets/image/login/icon_logout.png" class="t-yd-icon" alt="avatar">
                <span @click="toLogin" class="user-name">{{ $i18n.t('common.login') }}</span>
              </div>
              <!-- <span  @click="toLogin"><img src="@/assets/image/login/icon_logout.png" class="t-yd-icon" alt="avatar">{{ $i18n.t('common.login') }}</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="navbar-wrapper" id="navbar-wrapper">
        <div class="brand brandss">
          <a :href="`/${$i18n.locale=='en'?'':$i18n.locale}`" class="c-logo">
            <img src="@/assets/LOGO.svg" alt="HOLOWITS" class="navbar-logo" style="width: 110px; height: 21px;">
          </a>
        </div>
        <div class="brand brands">
          <a :href="`/${$i18n.locale=='en'?'':$i18n.locale}`" class="c-logo">
            <img src="@/assets/LOGO.svg" alt="HOLOWITS" class="navbar-logo" style="width: 155px; height: 21px;">
          </a>
        </div>

        <button class="hor-nav-toggle" type="button" @click="menuShow=!menuShow"/>

        <ul class="navbar-nav" :class="{'menu-show':menuShow}">
          <li class="navbar-link" v-for="(menu,index) in menuList" :key="index"> <!-- 一级菜单 -->
            <div class="navbar-dropdown-toggle" :class="{'_active':menu.activeUrl.includes(currentRouterPath)}">
              <router-link :to="menu.link" v-if="menu.link" @click.native="menuShow=false">
                <span>{{ menu.name }}</span>
              </router-link>
              <a href="" onclick="return false;" v-else>
                <span @click="menuShow=false">{{ menu.name }}</span>
              </a>
              <span class="c-arrow" v-if="menu.children&&menu.children.length" :class="{'arrow-expand':menu.expand}" @click="dropdownToggle(menu)"/>
            </div>

            <ul class="navbar-dropdown-menu" :class="{'dropdown-expand':menu.expand,'_active':menu.activeUrl.includes(currentRouterPath)}" v-if="menu.children&&menu.children.length">
                <li v-for="(subMenu,subIndex) in menu.children" :key="subIndex" class="navbar-link">
                  <router-link v-if="!subMenu.otherSite" :to="subMenu.link" @click.native="menuShow=false" style="width: 100%; display: block;">
                    <span>{{ subMenu.name }}</span>
                  </router-link>
                  <a v-else :href="subMenu.link" target="_blank">
                    <span @click="menuShow=false">{{ subMenu.name }}</span>
                  </a>
                  <ul v-if="subMenu.children && subMenu.children.length" style="margin-left: 25px;">
                    <li v-for="(item,index) in subMenu.children" :key="index" class="navbar-link t-chil-link">
                      <router-link v-if="!item.otherSite" :to="`${item.link}`" :disabled="item.disabled"  @click.native="menuShow=false">
                        <span>{{ item.name }}</span>
                      </router-link>
                      <a v-else :href="item.link" target="_blank">
                        <span @click="menuShow=false">{{ item.name }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
            </ul>
          </li>
          <li class="navbar-link tzy-custom-mobile-view">
            <div class="navbar-dropdown-toggle" :class="{'_active': false}">
              <a :href="'https://holowits.com/holoz-download-page/'" target="_blank" @click="menuShow=false">
                <span @click="menuShow=false" style="display: block; width: 100%;">HOLOZ DOWNLOAD</span>
              </a>
            </div>
          </li>
        </ul>

        <!-- <div class="t-m-navbar" :class="{'menu-show':menuShow}">
          <div class="t-m-navbar-list">
            <ul>
              <li class="t-m-navbar-link" v-for="(menu,index) in menuList" :key="index">
                <div class="link-item">
                  <div class="link-label">{{ menu.name }}</div>
                  <div class="link-item-child" v-if="menu.children && menu.children.length">
                    <ul>
                      <li v-for="(subMenu,subIndex) in menu.children" :key="subIndex">
                        <div class="link-label">{{ subMenu.name }}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div> -->
      </div>
    </div>
  </header>
</template>


<script>
import db from "@/utils/localstorage";
import {logout} from "@/api/login"

export default {
  name: 'Navbar',
  filters: {
    userAvatarFilter(name) {
      return name.includes('@') ? name.split('@')[0] : name;
    },
  },
  data() {
    return {
      menuShow: false,
      loginFlag: false,
      currentAccount: '',
      languageMap: {
        en: 'English',
        br: 'Português (Brasil)',
        ksa: 'اللغة العربية',
        tur: 'Türkçe',
        el: 'Ελληνική',
        es: 'Español'
      },
      menuList: [
        // {
        //   name: '',
        //   link: '/',
        //   activeUrl: ['/home'],
        // },
        {
          name: this.$i18n.t('navbar.products'),
          link: '/product-center',
          activeUrl: ['/product-center'],
          children: []
        },
        {
          name: this.$i18n.t('navbar.solution'),
          link: '/solution',
          activeUrl: ['/solution'],
          children: []
        },
        {
          name: this.$i18n.t('navbar.technicalSupport'),
          link: "/support",
          activeUrl: ['/support'],
          children: [
            // {
            //   name: this.$i18n.t('navbar.tools'),
            //   link: '/tools',
            //   activeUrl: ['/tools'],
            // },
            // {
            //   name: this.$i18n.t('navbar.technicalSupport'),
            //   link: "/support",
            //   activeUrl: ['/support'],
            // },
            // {
            //   name: this.$i18n.t('navbar.FAQ'),
            //   link: "/FAQ",
            //   activeUrl: ['/FAQ'],
            // },
            // {
            //   name: this.$i18n.t('navbar.terms'),
            //   link: "/terms",
            //   activeUrl: ['/terms'],
            // },
            // {
            //   name: this.$i18n.t('navbar.training'),
            //   link: "/training",
            //   disabled: false
            // },
            // {
            //   name: this.$i18n.t('navbar.knowledge'),
            //   link: "/knowledge",
            //   disabled: false
            // },
          ]
        },
        {
          name: this.$i18n.t('navbar.partners'),
          link: '/specialist',
          activeUrl: ['/specialist'],
          children: [
            {
              name: this.$i18n.t('navbar.channel'), // 'Partner Map',
              link: "/channel",
              activeUrl: ['/channel'],
            },
            {
              name: this.$i18n.t('navbar.forPartners'),
              link: '',
              activeUrl: [],
              children: [
                {
                  name: this.$i18n.t('navbar.certificate'),
                  link: "/specialist",
                  activeUrl: ['/specialist'],
                  disabled: false
                },
                // {
                //   name: this.$i18n.t('navbar.pocDemo'),
                //   link: "https://poc.holowits.com/",
                //   otherSite: true,
                //   disabled: false
                // },
                {
                  name: this.$i18n.t('navbar.becomeAPartner'),
                  link: "/reseller",
                  disabled: false
                },
                // {
                //   name: this.$i18n.t('navbar.successfulCases'),
                //   link: "/case",
                //   disabled: false
                // },
              ]
            },
            {
              name: this.$i18n.t('navbar.dealRegistration'),
              link: "/dr-list",
              disabled: false
            },
          ]
        },
        // {
        //   name: this.$i18n.t('navbar.channel'),
        //   link: "/channel",
        //   activeUrl: ['/channel'],
        // },
        {
          name: this.$i18n.t('navbar.resources'),
          link: '/documentation',
          activeUrl: [],
          children: [
            {
              name: this.$i18n.t('navbar.documentation'),
              link: '/documentation',
              activeUrl: ['/documentation'],
            },
            {
              name: this.$i18n.t('navbar.firmware'),
              link: "/firmware",
              activeUrl: ['/firmware'],
            },
            {
              name: this.$i18n.t('navbar.software'),
              link: "/soft-download",
              activeUrl: ['/soft-download'],
            },
            {
              name: this.$i18n.t('navbar.videoLibrary'),
              link: '/video-library',
              activeUrl: ['/video-library'],
            },
          ]
        },
        {
          name: this.$i18n.t('navbar.algorithmstore'),
          link: '/algorithmstore',
          activeUrl: ['/algorithmstore'],
        },
        {
          name: this.$i18n.t('navbar.aboutTitle'),
          link: '/about',
          activeUrl: ['/about'],
          children: [
            {
              name: this.$i18n.t('navbar.about'),
              link: '/about',
              activeUrl: ['/about'],
            },
            {
              name: this.$i18n.t('navbar.contact'),
              link: "/contact",
              activeUrl: ['/contact'],
            },
            {
              name: this.$i18n.t('navbar.news'),
              link: '/news',
              activeUrl: ['/news', '/newsDetail'],
            },
            // {
            //   name: 'HOLOZ DOWNLOAD',
            //   link: 'https://holowits.com/holoz-download-page/',
            //   otherSite: true,
            //   disabled: false
            // },
            // {
            //   name: this.$i18n.t('navbar.events'),
            //   link: '/new-product-launch',
            //   activeUrl: ['/new-product-launch'],
            // },
          ]
        },
      ],
    };
  },
  computed: {
    currentRouterPath() {
      return this.$route.path;
    },
  },
  mounted() {
    this.currentAccount = db.get("CLIENT_USER", "").account
    this.$nextTick(() => {
      this.loginFlag = db.get("CLIENT_TOKEN", "") == "" ? false : true
    })
  },

  methods: {
    goPresonalCenter() {
      this.$router.replace({
        path: '/personal-center'
      })
    },
    setLanguage(language) {
      switch(language) {
        case 'en':
          location.href = `${location.origin}${this.$route.path}`
          break;
        case 'br':
          location.href = `${location.origin}/br${this.$route.path}`
          break;
        case 'Arabic':
          location.href = `${location.origin}/ksa${this.$route.path}`
          break;
        case 'tur':
          location.href = `${location.origin}/tur${this.$route.path}`
          break;
        case 'el':
          location.href = `${location.origin}/el${this.$route.path}`
          break;
        case 'es':
          location.href = `${location.origin}/es${this.$route.path}`
          break;
      }
    },
    toLogin() {
      this.$router.push({path: '/login'})
    },
    logout() {
      this.$confirm(this.$t('tipInfo.confirmLogout'), null, {
        confirmButtonText: this.$t('common.confirm'),
        cancelButtonText: this.$t('common.cancel'),
        type: 'warning'
      }).then(() => {
        let param = {
          token: db.get('CLIENT_TOKEN', ''),
          userId: db.get('CLIENT_USER', {id: null}).id,
          clientId: process.env.VUE_APP_CLIENT_ID,
        }
        logout(param).finally(() => this.clean());
      })
    },
    clean() {
      db.delCookie('CLIENT_TOKEN', '/');
      db.delCookie('CLIENT_TENANT', '/');
      db.delCookie('CLIENT_REFRESH_TOKEN', '/');
      db.delCookie('CLIENT_EXPIRE_TIME', '/');
      db.delCookie('CLIENT_USER', '/');
      db.clear();
      location.reload();
    },
    dropdownToggle(menu) {
      this.$set(menu, 'expand', !menu.expand);
    },
  },
};
</script>

<style scoped lang="less">
.t-container {
  padding: 0;
  position: relative;

  // height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;

  .t-m-navbar {
    background-color: #fff;
    position: fixed;
    top: 124px;
    left: 0;
    width: 100%;
    height: calc(100vh - 124px);
    display: none;

    .t-m-navbar-list {
      height: calc(100% - 100px);
      ul {
        margin-left: 60px;
        margin-right: 60px;
      }
      .t-m-navbar-link {
        font-size: 40px;
        &:not(:last-child) {
          border-bottom: 0.5px solid #c4c4c4;
        }
  
        .link-item {
          .link-label {
            height: 100px;
            line-height: 100px;
          }
          .link-item-child {
            margin-bottom: 20px;
            .link-label {
              height: 80px;
              line-height: 80px;
            }
          }
        }
      }
    }

  }

  .top-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    background-color: #666666;
    color: #fff;
    height: 25px; 
    z-index: 10;
    
    .cont {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .avatar {
      display: flex;
      align-items: center;
      div + div {
        position: relative;
        margin-left: 30px;

        .avatar-wrapper {
          img {
            width: 18px;
            height: 18px;
          }
          img,span {
            vertical-align: middle;
          }
        }

        &::after {
          content: '|';
          color: #ffffff;
          position: absolute;
          top: 0;
          left: -15px;
        }
      }
    }

    .el-dropdown {
      color: #fff;
    }
  }
}

.tzy-custom-mobile-view {
  display: none;
}
.t-language-list {
  li {
    padding-left: 10px;
    height: 24px;
    line-height: 24px;
    &:hover {
      background-color: #66666633;
    }
  }
}

@media (min-width: 991px) {
  .brandss {
    display: none;
  }
  .t-container {
    // width: auto;
    max-width: 100%;
    margin: auto;
  }
  .cont {
    width: 83.3333%;
    margin: 0 auto;
  }
}

@media (min-width: 1200px) {
  .container {
    width: auto;
    max-width: 83.33333333%;
  }
}

@media (max-width: 991px) {
  .brands {
    display: none;
  }
  .t-yd-icon {
    width: 20px !important;
    height: 20px !important;
  }
  .avatar {
    padding-right: 20px;
  }
}

* {
  box-sizing: border-box;
}

.page-on-scroll {
  .navbar {
    background-color: #ffffff;
    // height: 65px;
    border-bottom: 1px solid rgb(230 239 243);

    // .navbar-wrapper {
    //   height: 65px;
    // }
  }
}

.navbar {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 0;

  a {
    &,
    &:visited,
    &:active,
    &:hover {
      text-decoration: none;
    }
  }

  .navbar-wrapper {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    justify-content: center;
    align-items: center;
    height: calc(100px);
    transition: 0.2s ease;
    padding-top: 25px;
  }

  .c-logo {
    outline: none;

    img {
      width: 100%;
      height: 100%;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  .navbar-nav {
    display: flex;
    background: none;
    position: static;
    margin: 0;
    padding: 0;
    flex-direction: row;
    transition: 0.2s ease;
    height: 100%;
  }

  .navbar-link {
    padding: 0 15px;
    position: relative;
    list-style: none;
    font-size: 15px;
    letter-spacing: 1px;
    font-style: normal;
    transition: all 0.2s ease;
    line-height: 100%;

    .hover-span {
      width: 402px;
      height: 128px;
      background: #ffff;
    }

    .navbar-link:hover .hover-span {
      opacity: 1;
    }

    span {
      padding-top: 2px;
    }

    &:hover > .navbar-dropdown-toggle {
      color: #c7000b;

      span {
        color: #c7000b;
      }
    }

    .navbar-dropdown-toggle {
      font-weight: bolder;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 19px;
      color: #fff;
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;

      &._active {
        * {
          font-weight: bold;
          color: #c7000b;
        }
      }

      a {
        color: #666666;
        display: block;
        width: 100%;
        white-space: nowrap;
      }
    }
  }

  .navbar-dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    right: auto;
    min-width: 235px;
    width: 400px;
    background: #fff;
    box-shadow: 0px 4px 7px 0px rgba(159, 159, 159, 0.53);
    border: 0;
    left: 0;
    z-index: 1000;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    border-radius: 0 0 6px 6px;

    .navbar-link {
      list-style: none;
      color: #333;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
      padding: 8px 25px;
      white-space: normal;
      letter-spacing: 1px;
      line-height: 28px;

      &:hover > a > span{
        color: #c7000b;
      }

      a {
        color: rgb(125, 134, 145);
      }
    }
    .t-chil-link{
      list-style-type: disc;
      // padding-top: 8px;
      padding-left: 0px;
      line-height: 18px;
      & > a > span {
        font-size: 13px;
      }
      & /deep/ .el-link {
        & span {
          font-size: 13px;
        }
        &:hover span {
          color: #c7000b;
        }
      }
    }
  }

  .hor-nav-toggle {
    position: relative;
    top: -1px;
    background: rgb(245, 246, 248);
    padding: 5px 8px 6px 8px;
    border: 0;
    outline: none;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    display: none;

    &:hover {
      background: rgb(230, 234, 237);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 25px;
      display: block;
      height: 2px;
      z-index: 1;
      color: rgb(186, 195, 205);
      background: currentColor;
      box-shadow: 0 -5px 0 currentColor, 0 5px 0 currentColor;
      user-select: none;
    }
  }
}

@media (min-width: 991px) {
  .navbar-link:hover {
    .navbar-dropdown-menu {
      display: block;
    }
  }
}

@media (max-width: 991px) {
  html {
    width: 100% !important;
  }

  body {
    width: 100% !important;
  }

  .navbar {
    width: 100%;
    border-bottom: none !important;
    box-shadow: 0px 3px 9px -4px rgba(0, 0, 0, 0.25);
    // height: 85px;

    .navbar-wrapper {
      height: calc(75px) !important;
    }

    .navbar-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      // justify-content: flex-start;
      align-items: center;
      transition: 0.2s ease;

      .brandss {
        img {
          height: 50px !important;
          width: 150px !important;
        }
      }
    }

    .hor-nav-toggle {
      display: inline-block;
    }

    .t-m-navbar  {
      &.menu-show {
       display: block;
      }
    }

    .navbar-nav {
      margin: 0;
      padding: 5px 0;
      width: 100%;
      transition: all 0.2s;
      left: 0;
      position: absolute;
      top: 100%;
      background: rgb(57, 64, 72);
      height: auto;
      display: none;

      &.menu-show {
       display: block;
      }

      .navbar-link {
        float: none;
        font-size: 13px;
        text-transform: uppercase;
        letter-spacing: 0px;
        font-weight: 400;
        line-height: 20px;
        color: rgb(235, 237, 242);
        border-bottom: 1px solid rgb(64, 72, 81);

        .navbar-dropdown-toggle {
          border-bottom: 1px solid rgb(64, 72, 81);
          padding: 10px 5px 10px 5px;
        }

        span {
          padding-top: 0;
        }

        .navbar-dropdown-toggle {
          color: currentColor;
          font-size: 13px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 400;

          span {
            color: rgb(235, 237, 242);
          }

          .c-arrow {
            position: absolute;
            right: 4px;
            padding: 3px 0;
            font-size: 13px;
            height: 26px;
            margin-top: -3px;
            text-align: center;
            width: 30px;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            transform-origin: center;

            &.arrow-expand {
              transform: rotateZ(-90deg) translateX(1px);
            }

            &:after {
              content: '';
              width: 5px;
              height: 5px;
              border-left: currentColor 1px solid;
              border-top: currentColor 1px solid;
              transform: rotateZ(-45deg);
            }
          }
        }

        .navbar-dropdown-menu {
          position: static;
          background: none;
          box-shadow: none;
          padding: 0;
          width: 100%;

          &.dropdown-expand {
            display: block;
          }

          .navbar-link a {
            color: rgb(189, 195, 212);
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

.navbar-wrapper {
  position: relative;

  &:after {
    content: '';
    width: 30px;
  }
}

.avatar {
  cursor: pointer;
  // position: absolute;
  // right: -20px;
  // top: 50%;
  // transform: translateY(-50%);

  img {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px
  }
}

@media (max-width: 991px) {
  .tzy-custom-mobile-view {
    display: block;
  }
  .navbar-wrapper {
    &:after {
      display: none;
    }
  }

  .avatar {
    right: 50px;
  }
}
</style>
<style>
body {
  font-family: 'Microsoft Yahei', sans-serif !important;
}
</style>

