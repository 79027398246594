import db from '@/utils/localstorage'

export default {
  namespaced: true,
  state: {
    list: {},
    fileNames: [],
    fileStatus: {}
  },
  mutations: {
    setFileNames (state, val) {
        if (!state.fileNames.includes(val)) {
            state.fileNames.unshift(val)
            db.save('DOWN_NAMES', state.fileNames)
        }
    },
    setList (state, val) {
      db.save('DOWN_LIST', {...state.list, ...val})
      let key = Object.keys(val)[0];
      let _value_ = JSON.parse(JSON.stringify(state.list));
      _value_[key] = val[key]
      state.list = _value_
    },
    setListProperty(state, val) {
      let {key, property, value} = val;
      let _value_ = JSON.parse(JSON.stringify(state.list));
      _value_[key][property] = value
      state.list = _value_
    },
    setStatus (state, val) {
      let key = Object.keys(val)[0];
      let _value_ = JSON.parse(JSON.stringify(state.fileStatus));
      _value_[key] = val[key]
      state.fileStatus = _value_
    },
    setStatusProperty(state, val) {
      let {key, property, value} = val;
      let _value_ = JSON.parse(JSON.stringify(state.fileStatus));
      _value_[key][property] = value;
      state.fileStatus = _value_;
    }
  }
}
