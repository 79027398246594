<template>
  <div class="main-content" :class="{'page-on-scroll':isScroll}">
    <Navbar/>
    <AppMain/>
    <Footer/>
    <ContactUs/>
    <download-list></download-list>
    <el-backtop :right="20" :bottom="220"></el-backtop>
    <div class="contact fixed-btn" @click="toPage1">
      <i class="el-icon-message"></i>
    </div>
  </div>
</template>

<script>
  import {Navbar, AppMain, Footer} from '@/layout/components';
  import ContactUs from './components/ContactUs';
  import DownloadList from '@/views/common/plug/DownloadList';
  import {debounce} from 'lodash';

  export default {
    name: 'Layout',
    components: {
      Navbar,
      AppMain,
      Footer,
      ContactUs,
      DownloadList
    },
    data() {
      return {
        isScroll: false,
      };
    },
    mounted() {
      window.addEventListener('scroll', () => this.contentScroll(), false);
    },
    methods: {
      toPage1() {
        this.$router.push({
          path: '/contact',
        });
      },
      // contentScroll: debounce(function(e) {
      //   const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //   this.isScroll = scrollTop >= 100;
      // }, 100, {'maxWait': 100, 'leading': false}),
      contentScroll(e) {
          const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
          this.isScroll = scrollTop >= 100;
      }
    },
  };
</script>

<style scoped>
  .contact {
    right: 20px;
    bottom: 160px;
  }
  .fixed-btn {
    position: fixed;
    background-color: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #c7000b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-shadow: 0 0 6px #0000001f;
    cursor: pointer;
    z-index: 5;
  }

  .fixed-btn:hover {
    background-color: #F2F6FC;
  }
  /* .main-content {
    padding-top: 100px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  } */

  /* @media (max-width: 991px) {
    .main-content {
      padding-top: 65px;
    }
  } */

</style>
