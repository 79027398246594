const db = {
  save (key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  get (key, defaultValue = {}) {
    try {
      return JSON.parse(localStorage.getItem(key)) || defaultValue
    } catch (err) {
      return defaultValue
    }
  },
  remove (key) {
    localStorage.removeItem(key)
  },
  clear() {
    try {
      let arr = []
      for (let i = 0; i < localStorage.length; i++) {
        console.log(localStorage.key(i).substring(0, 7))
        if (localStorage.key(i).substring(0, 7) == 'CLIENT_') {
          arr.push(localStorage.key(i))
        }
      }
      for (let i = 0; i < arr.length; i++) {
        localStorage.removeItem(arr[i])
      }
    } catch (err) {
      localStorage.clear()
    }
  },
  setCookie(name, value, expiredays, path) {
    // var strSec = getSec(time);
    var exp = new Date();
    // exp.setTime(exp.getTime() + strSec * 1);
    exp.setDate(exp.getDate() + expiredays)
    //path=/，表示cookie能在整个网站下使用，path=/temp，表示cookie只能在temp目录下使用
    path = path == "" ? "" : ";path=" + path;
    //设置cookie的名称、值、失效时间
    document.cookie = name + "=" + value + ";expires="+ exp.toUTCString() + ";domain=.holowits.com" + path;
  },
  getCookie(name) {
    //获取当前所有cookie
    var strCookies = document.cookie;
    //截取变成cookie数组
    var array = strCookies.split(';');
    //循环每个cookie
    for (let item of array) {
      if (item.includes(name)) {
        return item.replaceAll(' ', '').replace(name+'=', '')
      }
    }
    return null;
 },
 delCookie(name, path) {
    //获取cookie是否存在
    var value = this.getCookie(name);
    // console.log(value)
    path = path == "" ? "" : ";path=" + path;
    if (value != null) {
      document.cookie = name + "=" + ";expires="+ (new Date(0)).toUTCString() + ";path=/";
    }
  }
}

export default db
