import axiosApi from '@/api/axiosApi';

//登录
export const login = (data, meta) => axiosApi({
    method: 'POST',
    url: '/oauth/noToken/login',
    data,
    meta
});

//退出登录
export const logout = (data, meta) => axiosApi({
    method: 'POST',
    formData: true,
    url: '/oauth/noToken/logout',
    data,
    meta
});

//获取验证码
export const getCaptcha = (randomId) => axiosApi({
    method: 'GET',
    url: '/oauth/anno/captcha' + `?key=${randomId}`,
    responseType: 'arraybuffer',
    meta: {
        "X-isToken": false
    }
});

//修改密码
export const updatePassword = (data) => axiosApi({
    method: 'POST',
    url: `/authority/user/password`,
    data,
});

// 发送邮件
export const sendEmail = (data) => axiosApi({
    method: 'POST',
    url: `/msg/verification/anno/sendEmail`,
    data,
})

//忘记密码
export const forgetPassword = (data) => axiosApi({
    method: 'POST',
    url: `/authority/user/forgotpwd`,
    data,
});

// 申请账号
export const applicant = (data) => axiosApi({
    method: 'POST',
    url: `/partner/user/applicant`,
    data,
})

// 验证验证码
export const codeCheck= (key, code) => axiosApi({
    method: 'GET',
    url: `/oauth/anno/check?key=${key}&code=${code}`,
})

