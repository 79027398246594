import db from '@/utils/localstorage'

export default {
  namespaced: true,
  state: {
    refreshToken: db.get('CLIENT_REFRESH_TOKEN'),
    token: db.get('CLIENT_TOKEN', ''),
    tenant: db.get('CLIENT_TENANT', ''),
    expireTime: db.get('CLIENT_EXPIRE_TIME', 0),
    user: db.get('CLIENT_USER', {}),
    permissions: db.get('CLIENT_PERMISSIONS', []),
    authorityResource: db.get('CLIENT_AUTHORITY_RESOURCE', {"enabled": true, "caseSensitive": false, "resourceList": [], "roleList": []}),
    routes: db.get('CLIENT_USER_ROUTER', []),
    role: db.get('CLIENT_ROLR', [])
  },
  mutations: {
    setTenant (state, val) {
      db.save('CLIENT_TENANT', val)
      state.accessToken = val
    },
    setRefreshToken (state, val) {
      db.save('CLIENT_REFRESH_TOKEN', val)
      state.refreshToken = val
    },
    setToken (state, val) {
      db.save('CLIENT_TOKEN', val)
      state.token = val
    },
    setRole (state, val) {
      db.save('CLIENT_ROLR', val)
      state.role = val
    },
    setExpireTime (state, val) {
      db.save('CLIENT_EXPIRE_TIME', val)
      state.expireTime = val
    },
    setUser (state, val) {
      db.save('CLIENT_USER', val)
      state.user = val
    },
    setPermissions (state, val) {
      db.save('CLIENT_PERMISSIONS', val)
      state.permissions = val
    },
    setAuthorityResource (state, val) {
      db.save('CLIENT_AUTHORITY_RESOURCE', val)
      state.authorityResource = val
    },
    setRoutes (state, val) {
      db.save('CLIENT_USER_ROUTER', val)
      state.routes = val
    }
  }
}
