export default {
  common: {
    querySpecifications: '技术规格查询',
    login: '登录',
    logout: '退出登录',
    Home: '首页',
    ProductCenter: '产品中心',
    ProductDetail: '产品详情',
    WhatIsaSoftwareDefinedCamera: '什么是软件定义摄像机',
    Solutions: '解决方案',
    SolutionsDetail: '解决方案详情',
    Products: '产品中心',
    Distribution: '分销专区',
    Tools: '设计工具',
    Support: '技术支持',
    Documentation: '资料中心',
    news: '新闻中心',
    newsDetail: '新闻详情',
    SoftwareDefinedCamera: '软件定义摄像机',
    IntelligentVisionSystem: '智能微边缘',
    Accessory: '配套件',
    ContactUs: '联系我们',
    NewsRoom: 'Holowits新闻',
    NewsRoomDetail: 'Holowits新闻详情',
    Partner: '合作伙伴',
    Resources: '资源',
    ResourceCenter: '资料中心',
    VideoLibrary: '视频中心',
    AftersalesSupport: '售后支持',
    Aftersales: '售后智能问答',
    Privacy: '隐私保护',
    TermsOfUse: '法律声明',
    clear:"清空",
    RefineBy: '筛选',
    placeholder: '请输入型号/名称/产品概述',
    Favorites: '加入收藏',
    Compare: '加入对比',
    More: '加载更多',
    all: '全部',
    enter: '请输入型号/名称/产品概述',
    collapse: '收起',
    expand: '展开',
  },
  login: {
    title: '使用用户ID登录'
  },
  //导航栏
  navbar: {
    about: '关于HOLOWITS',
    products: '产品',
    productCenter: '产品中心',
    technicalSupport: '技术支持',
    solution: '解决方案',
    documentation: '资料中心',
    support: '技术支持',
    news: '新闻中心',
    tools: '设计工具',
    partners: '合作伙伴',
    resources: '相关资源',
    forPartners: 'FOR PARTNERS',
    forCustomers: 'FOR CUSTOMERS',
    channel:"FOR CUSTOMERS",
    videoLibrary: "视频中心",
  },
  // 产品中心
  products: {
    productTypes: '商品类型',
    sort: '排序',
    overAll: '综合排序',
    hot: '最热',
    latest: '最新',
    listPrice: '目录价',
    preSales: '提前销售',
    model: '型号',
    processor: '芯片',
    memory: '内存',
    computingPower: '算力',
  },
  // 产品详情
  productDetail: {
    productDetail: '产品详情',
    accessoryDetail: '配套件详情',
    specifications: '技术规格',
    certification: '认证地图',
    introduction: '产品介绍',
    download: '资料下载',
    huaweiCcsc: 'imgs/product_detail/map.png',
    clickHere: '点击进入',
    dataSheet: '彩页',
    afterSalesDocuments: '售后资料',
    copy: '一键复制',
    reportError: '产品纠错',
    description: '问题描述',
    describeError: '请输入具体需求或问题',
    picture: '问题图片',
    onlyJpg: '只支持.jpg格式',
    attachment: '其他附件',
    selectFile: '请选择文件',
    anonymous: '匿名评价',
    submit: '提交',
    accessory: '配套件',
    technicalSpec: '技术参数',
    dimensions: '尺寸图',
    remarks: '备注及说明',
    optionalFitting: '可选配件',
    favorites: '收藏报价',
    keySpec: '关键规格',
    viewAll: '登录查看全部参数',
    clicktoLoad: '点此获取',
    completeSpecificationList: '全量规格清单',
  },
  // 技术支持
  support: {
    documentation: '文档',
    softwareDownload: '下载',
    video: '视频',
    searchPlaceholder: '请输入',
  },
  // 新闻中心
  news: {
    searchPlaceholder: '请输入',
    search: '搜索',
  },
  // 安防工具
  securityTools: {
    cameradetectionDistance: '摄像机监控距离计算',
    disable: '未开启',
    enable: '开启',
    videoResolution: '视频分辨率',
    encodingBitRate: '编码码率',
    NumberOfNVRs: '计算NVR数量',
    microCheckpoint: '微卡口',
    ePoliceCheckpoint: '电警卡口',
    targetDetection: '目标检测/识别',
    //提示信息
    microResolutionTips: '',
    microInstallationTips: '',
    ePoliceResolutionTips: '',
    ePoliceInstallationTips: '',
    targetResolutionTips: '',
    targetInstallationTips: '',

  },
  // 提示信息
  tipInfo: {
    exportFailure: '导出失败',
    addProToComp: '请先添加对比的产品',
    selectLeastTwo: '产品对比至少为两项哦',
    selectMaximum: '最多四项哦！',
    copySuccess: '复制成功',
    noData: '无数据',
    selectOther: '抱歉，无当前设备信息，请选择其他产品。',
    onlyJpg: '问题图片只支持.jpg格式',
    exeNoUpload: '不可上传exe格式的文件',
    problemNotEmpty: '问题描述不能为空',
    submitSuccess: '提交成功',
    submitFailed: '提交失败，请稍后再试',
    loginSuccess: '登录成功',
    errorPwd:"两次输入密码不一致",
  },
  technologySupport: {
    support: '技术支持',
  },
  documentation: {
    searchPlaceholder: '请输入关键字搜索',
    search: '搜索',
    productFilter: '产品',
    typeFilter: '搜索',
    formatFilter: '格式',
    publishDate: '根据发布日期排序',
    downloadTimes: '根据下载次数排序',
    hotDoc: '热门资料',
    result: '条结果',
    download: '下载',
    batDownload: '批量下载',
    checkAll: '全选',
    paginalNumber: '每页',
  },
  faq: {
    faq: '常见问题',
  },
  contactUs: {
    contactUs: 'Contact Us',
    options:{
      pricingInfo:'Pricing/Info',
      serviceRequests:'Service Requests',
    },
    pricingInfo: {
      Title:'GET PRICING/INFO',
      Desc1:'Please fill in the form below.',
      Desc2:'Our sales representative will contact you as soon as possible.',
      Mandatory: 'Mandatory',
      FirstName: '姓',
      LastName: '名',
      Email: '邮箱',
      Telephone: '联系电话',
      CountryRegion: '国家/地区',
      Company: '公司',
      Industry: '行业',
      JobTitle: '职位',
      FavoriteProducts: '产品解决方案',
      Budget: '预算',
      BrieflyPlaceholder: 'Briefly outline your project requirements. ',
      ConfirmText: 'I agree to receive information about products, solutions, services, and offerings from HOLOWITS and HOLOWITS authorized partners. I understand that I can unsubscribe at any time.',
      Yes: '是',
      No: '否',
      AgreeText: 'I understand and agree to the use and transfer of my personal information by HOLOWITS according to HOLOWITS’s ',
      PrivacyPolicy: 'Privacy Policy',
      TermsOfUse: 'Terms of Use',
      And: '和',
      placeholder:'请输入',
      submit:'提交',
      fieldRequired:'This field is required.',
      emailValidator: {
        'at-error': '必须包含@',
        'account-letter-forbidden': '@前面字符包含非法字符',
        'prevDot-error': '@前面字符开头和结尾不能为“.”',
        'double-dot-error:': '不能包含连续的“..”',
        'domain-error': '@后面至少包含一个域名',
        'length-error': '长度不能超过254个字符',
        'nextLetter-forbidden': '@后面包含非法字符{0}',
        'nextLine-error': '@后面字符开头和结尾不能为“-”',
        'account-length-error': '@前面字符数不能超过63',
      },
    },
  },
};
