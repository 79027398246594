import db from '@/utils/localstorage'

export default {
  namespaced: true,
  state: {
    softs: db.get('SOFT_ACTIVE_SOFTS'),
  },
  mutations: {
    setSofts (state, val) {
      db.save('SOFT_ACTIVE_SOFTS', {...state.softs, ...val})
      state.softs = {...state.softs, ...val}
    },
  }
}
