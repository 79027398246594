<template>
  <section class="app-main">
      <keep-alive include="productCenterList,scenarioSolutionList">
        <router-view :key="key"/>
      </keep-alive>
  </section>
</template>

<script>
  export default {
    name: 'AppMain',
    computed: {
      key() {
        return this.$route.path;
      },
    },
  };
</script>

<style lang="less" scoped>
  .app-main {
    padding-top: 100px;
    min-height: calc(100vh - 162px - 100px);
    // min-height: 100%;
    flex: 1;
    // max-width: 1920px;
    margin: 0 auto;
    width: 100%;
    box-sizing: content-box;
  }
  @media only screen and (max-width: 991px) {
    .app-main {
      padding-top: 75px;
      min-height: calc(100vh - 245.06px);
      .app-body {
        min-height: calc(100vh - 162px - 75px);
      }
    }
  }
</style>

